import React, { useContext, useEffect, useState, useRef } from 'react'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import { intersection, first, get } from 'lodash'
import moment from 'moment'
import qs from 'qs'
import * as yup from 'yup'
import { useFormikContext } from 'formik'
import { Form } from '@middesk/components'
import Body from './Body'
import { ApplicationContext } from '../contexts/ApplicationProvider'
import { AccountContext } from '../contexts/AccountProvider'
import { AuthContext } from '../contexts/AuthProvider'
import { StateDataContext } from '../contexts/StateDataProvider'
import api from '../lib/api'
import SystemSidebar from './System/Sidebar'
import {
  applicationPayload,
  getStockTable,
  prefillInfo,
  selectApplicationKeys,
  isLlcEntityType,
  localJurisdictions,
  stateJurisdictions
} from '../lib/helpers'
import {
  AdditionalCompanyDetails,
  AddressDetails,
  ApplicantDetails,
  CompanyDetails,
  SelectStateApplication,
  Leadership,
  OrderComplete,
  Payment,
  ReviewAndFile,
  CompanyStockTable,
  SecretaryOfStateIntro,
  SecretaryOfStateRegistration,
  SecretaryOfStatePayment,
  LocalTaxPage
} from '../pages'
import { DynamicStatePage } from '../pages/DynamicStatePage'
import {
  AgentApplication,
  Company,
  ForeignQualification,
  FormValues,
  TransactionalPricingData,
  LocalRegistrationPageProps
} from '../types'
import Sidebar from './Sidebar'
import InvitationAlert from './InvitationAlert'
import Page from './System/Layout/Page'
import { PagesManager } from './PagesManager'
import MobileTopbar from './System/MobileTopbar'
import { theme } from '@middesk/components'
import APICompany from '../lib/api/company'
import styled from 'styled-components'
import PayrollReports, { showPayrollReports } from './Forms/PayrollReports'
import { MANAGED_BY_MIDDESK } from '../lib/constants'

const { colors, spacing } = theme

const StyledEmptySidebar = styled(SystemSidebar)`
  background-color: ${colors.white};
  width: ${spacing.compact};

  a:first-child {
    margin-right: 0;
  }
`

export const STOCK_TABLE_STATES = ['IL', 'AK', 'WI', 'AZ', 'NM', 'SC']

const includesStockTableState = (values: {
  foreign_qualifications: Array<ForeignQualification>
}) =>
  intersection(
    [...values.foreign_qualifications].map(({ state }) => state),
    STOCK_TABLE_STATES
  ).length > 0

/**
 * RevalidateOnSchemaChange takes a validationSchema and revalidates the Formik
 * form any time the schema is updated.
 */
const RevalidateOnSchemaChange = ({
  validationSchema
}: {
  validationSchema: yup.ObjectSchema
}) => {
  const { validateForm } = useFormikContext()

  useEffect(() => {
    validateForm()
  }, [validationSchema, validateForm])

  return null
}

const NewRegistrationIntake = (): JSX.Element => {
  const {
    user,
    account,
    inGuestMode,
    transactionalAccount,
    fetching
  } = useContext(AuthContext)
  const {
    existingSubscription,
    existingPaymentMethod,
    setFetchingExistingPaymentMethod,
    setFetchingExistingSubscription
  } = useContext(AccountContext)
  const {
    state,
    setState,
    questions,
    taxTypesByIntent,
    localJurisdictionsInState,
    localQuestionsMap
  } = useContext(ApplicationContext)
  const { requiresForeignQualification } = useContext(StateDataContext)
  const [validationSchema, setValidationSchema] = useState(yup.object())
  const [prefilling, setPrefilling] = useState(true)
  const [fetchingApplication, setFetchingApplication] = useState(true)
  const didMountRef = useRef(false)
  const { id } = useParams<{ id: string }>()
  const [application, setApplication] = useState<AgentApplication>({ id })
  const [company, setCompany] = useState<Company | undefined>()
  const [initialValues, prefill] = useState({
    formation_state: null,
    legal_name: null,
    addresses: [],
    owners: [],
    accept_tos: false,
    questions: {},
    totals: {},
    tax_registrations: [],
    foreign_qualifications: [],
    fiscal_year_end: '',
    formation_date: '',
    state: '',
    stock_table: {},
    sos_registration_selection: null
  })
  const [shouldRenderStockTable, setShouldRenderStockTable] = useState<boolean>(
    false
  )
  const [shouldRenderSOS, setShouldRenderSOS] = useState<boolean>(false)
  const [showPaymentInformation, setShowPaymentInformation] = useState<boolean>(
    false
  )
  const [
    showSOSPaymentInformation,
    setShowSOSPaymentInformation
  ] = useState<boolean>(false)
  const [
    shouldShowDynamicStatePage,
    setShouldShowDynamicStatePage
  ] = useState<boolean>(true)
  const [
    localJurisdictionRegistrations,
    setLocalJurisdictionRegistrations
  ] = useState<LocalRegistrationPageProps[]>([])
  const [error, setError] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [pricingData, setPricingData] = useState<TransactionalPricingData>()

  const history = useHistory()
  const { push } = history

  const { page: pageQueryParam } = qs.parse(history.location.search, {
    ignoreQueryPrefix: true
  })

  const selectedPackage = get(account, 'settings.agent.package_type', 'basic')
  const skipPaymentSetting = get(account, 'settings.agent.skip_payment', false)

  const sandboxMode = api.sandboxMode()

  const getInitialPage = (pageQueryParam: any) => {
    if (!pageQueryParam) {
      return 0
    }

    const pageNum = Number(pageQueryParam)
    if (!pageNum || pageNum < 0) {
      return 0
    }

    return pageNum
  }

  let initialPage = getInitialPage(pageQueryParam)
  const [pageNumber, setPageNumber] = useState(initialPage)

  const onNext = async (values: FormValues, submit = false) => {
    const goToNextPage = () => {
      setURLSearchParams(pageNumber + 1)
      setPageNumber(pageNumber + 1)
      window.scrollTo(0, 0)
    }

    setError('')
    setIsSubmitting(true)

    saveApplication(values, submit)
      .then(() => {
        goToNextPage()
      })
      .catch((e: any) => {
        setError(e)
      })
      .finally(() => setIsSubmitting(false))
  }

  const onCancel = () => {
    setURLSearchParams(pageNumber - 1)
    setPageNumber(pageNumber - 1)
  }

  const updateValidationSchema = (schema: any) => {
    setValidationSchema(schema)
  }
  const handleSkipStockTable = (values: FormValues) => {
    setShouldRenderStockTable(
      includesStockTableState(values) && !isLlcEntityType(values?.entity_type)
    )
  }

  const handleDynamicStatePage = (values: FormValues) => {
    setShouldShowDynamicStatePage(
      stateJurisdictions(values.jurisdictions).length > 0
    )
  }

  const handleSkipSOS = (values: FormValues) => {
    setShouldRenderSOS(
      transactionalAccount &&
        requiresForeignQualification(state, values?.questions || {})
    )
  }

  const handleSosPaymentInformation = (values: any) => {
    setShowSOSPaymentInformation(
      !!skipPaymentSetting &&
        !existingPaymentMethod &&
        values.sos_registration_selection == 'middesk'
    )
  }

  const handleLocalJurisdictionRegistrations = (values: FormValues) => {
    if (localJurisdictionsInState.length === 0) {
      return
    }

    const names = localJurisdictions(values.jurisdictions).map(jurisdiction => {
      const { slug, label } =
        (localJurisdictionsInState || []).find(
          local => local.slug == jurisdiction
        ) || {}

      return {
        jurisdictionSlug: slug,
        jurisdictionLabel: label
      }
    })

    setLocalJurisdictionRegistrations(names)
  }

  const setURLSearchParams = (pageNum: number) => {
    const currentUrlParams = qs.parse(history.location.search, {
      ignoreQueryPrefix: true
    })
    const queryString = qs.stringify(
      { ...currentUrlParams, page: pageNum },
      { addQueryPrefix: true }
    )
    push(history.location.pathname + queryString)
  }

  const saveApplication = (values: FormValues, submit: boolean) => {
    return api
      .put(`/v1/agent/applications/${application.id}`, {
        ...applicationPayload(values, questions, state, localQuestionsMap),
        submit
      })
      .catch((e: any) => setError(`Error saving application: ${e}`))
  }

  useEffect(() => {
    setFetchingExistingPaymentMethod(true)
    setFetchingExistingSubscription(true)
  }, [])

  useEffect(() => {
    // Redirect any non-transactional users without a package selected on the account to /select-package
    if (
      user &&
      !user.account.settings.agent.package_type &&
      !transactionalAccount &&
      !fetching
    ) {
      push({
        pathname: '/select-package',
        search: `?redirect_path=${history.location.pathname}`
      })
    }
  }, [user, fetching])

  // enables forward and backward button
  useEffect(() => {
    return history.listen(location => {
      const currentUrlParams = qs.parse(location.search, {
        ignoreQueryPrefix: true
      })
      const { page: currentPage } = currentUrlParams
      const pageNum = currentPage ? Number(currentPage) : 0
      if (typeof pageNum !== 'number') {
        return
      }

      if (history.action === 'POP') {
        setPageNumber(pageNum)
      }
    })
  }, [history])

  useEffect(() => {
    if (didMountRef.current) {
      setFetchingApplication(false)
    } else {
      didMountRef.current = true
    }
  }, [application])

  const { search } = useLocation()
  const parsedParams = qs.parse(search, {
    ignoreQueryPrefix: true
  })

  const managedTaxTypes = taxTypesByIntent[MANAGED_BY_MIDDESK] || []
  const renderPayrollReports = showPayrollReports({ state, managedTaxTypes })
  const base_pages = [
    SelectStateApplication,
    ApplicantDetails,
    CompanyDetails,
    AdditionalCompanyDetails,
    ...(shouldRenderStockTable ? [CompanyStockTable] : []),
    AddressDetails,
    Leadership,
    ...(shouldShowDynamicStatePage ? [DynamicStatePage] : []),
    ...Array(localJurisdictionRegistrations.length).fill(LocalTaxPage),
    ...(renderPayrollReports ? [PayrollReports] : []),
    ReviewAndFile,
    ...(showPaymentInformation && !sandboxMode ? [Payment] : [])
  ]

  const fq_pages = [
    SecretaryOfStateIntro,
    SecretaryOfStateRegistration,
    ...(showSOSPaymentInformation && !sandboxMode
      ? [SecretaryOfStatePayment]
      : [])
  ]

  const pages = shouldRenderSOS
    ? [...base_pages, ...fq_pages, OrderComplete]
    : [...base_pages, OrderComplete]

  const firstLocalPageAt = pages.findIndex(page => page == LocalTaxPage)

  let pageCount = 0
  const pageMap = pages.reduce((acc, page) => {
    pageCount++
    return { ...acc, [page.pageName || '']: pageCount - 1 }
  }, {})

  useEffect(() => {
    let storedApplicationState: any
    const loadApplication = async () => {
      let applicationJson
      let companyJson

      try {
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        applicationJson = await api.get(
          `/v1/agent/applications/${application.id}`
        )
        companyJson = await APICompany.show(applicationJson.company_id)

        storedApplicationState = selectApplicationKeys(applicationJson)
      } catch (e: any) {
        if (e.status === 404) {
          window.location.href = '/'
        }
      }

      // If explicit page passed in, override last page from application state
      initialPage = getInitialPage(pageQueryParam)
      setPageNumber(initialPage)
      setApplication(applicationJson)
      setCompany(companyJson)

      let prefillData = {
        ...initialValues,
        ...{
          contact_email: user?.email,
          contact_name: user?.name,
          stock_table: getStockTable(companyJson?.stock_table),
          ...storedApplicationState
        }
      }

      if (
        applicationJson.application_invitation &&
        applicationJson.saved_tax_registrations.length === 0
      ) {
        prefillData.tax_registrations = [
          { state: applicationJson.application_invitation.state }
        ]
      }

      // Get Tax Registration Transactional Prices
      if (!pricingData) {
        const tax_registration_types =
          applicationJson.tax_registration_types || []

        api
          .get('/v1/agent/transactional_prices', { tax_registration_types })
          .then(setPricingData)
      }

      // Convert dates for pre-fills
      const datePlaceholder = 'MM/DD/YYYY'
      prefillData.fiscal_year_end = prefillData.fiscal_year_end
        ? moment(prefillData.fiscal_year_end).format(datePlaceholder)
        : ''

      prefillData.formation_date = prefillData.formation_date
        ? moment(prefillData.formation_date).format(datePlaceholder)
        : ''

      prefillData.acquisition_date = prefillData.acquisition_date
        ? moment(prefillData.acquisition_date).format(datePlaceholder)
        : ''

      prefillData.previous_entity_type_change_date = prefillData.previous_entity_type_change_date
        ? moment(prefillData.previous_entity_type_change_date).format(
            datePlaceholder
          )
        : ''

      if (parsedParams.prefill === 'true') {
        prefillData = { ...prefillData, ...prefillInfo() }
      }

      const tempState = first<{ state: string | undefined }>(
        prefillData.tax_registrations
      )

      if (tempState && tempState?.state) {
        setState(tempState.state)

        const state_addresses_count = (
          prefillData.secondary_addresses[tempState.state] || []
        ).length
        prefillData.has_state_locations = state_addresses_count > 0
        if (state_addresses_count > 0) {
          prefillData.state_locations_count = state_addresses_count
        }
      }

      prefill(prefillData)

      // This is Recurring Payment Information
      setShowPaymentInformation(
        !skipPaymentSetting &&
          !existingSubscription &&
          selectedPackage !== 'unlimited'
      )

      setPrefilling(false)
    }

    loadApplication()
  }, [
    existingSubscription,
    existingPaymentMethod,
    skipPaymentSetting,
    selectedPackage,
    pageQueryParam,
    user
  ])

  const invitationMode = application.application_invitation !== null
  const usePartnerLogo = inGuestMode || invitationMode
  const logo = usePartnerLogo ? application.partner_logo : undefined

  if (
    !isSubmitting &&
    application?.submitted_at &&
    pages[pageNumber] != OrderComplete
  ) {
    let url = '/status'

    if (application.application_invitation) {
      url = inGuestMode
        ? '/guest/invitations'
        : `/companies/${application.company_id}/invitations`
    }

    return <Redirect to={url} />
  }

  const localIndex = pageNumber - firstLocalPageAt
  const localJurisdictionLabel =
    localIndex >= 0 && localJurisdictionRegistrations[localIndex]
      ? localJurisdictionRegistrations[localIndex].jurisdictionLabel
      : ''

  return (
    <>
      <Page background={colors.white}>
        {pageNumber === 0 ? (
          <StyledEmptySidebar />
        ) : (
          <Sidebar
            {...{
              hasStockTablePage: shouldRenderStockTable,
              hasDynamicStatePage: shouldShowDynamicStatePage,
              showPaymentInformation,
              requiresSosRegistration: shouldRenderSOS,
              showPayrollReports: renderPayrollReports,
              current: pageNumber + 1,
              logo,
              hideMenu: usePartnerLogo,
              fetchingApplication: fetchingApplication,
              companyId: company?.id,
              localJurisdictionRegistrations
            }}
          />
        )}

        <>
          <MobileTopbar
            {...{
              logo,
              fetchingApplication
            }}
          />
          {application?.application_invitation && (
            <InvitationAlert invitation={application.application_invitation} />
          )}
          {
            <Body
              title={
                !fetchingApplication
                  ? (pages[pageNumber].title(
                      application,
                      localJurisdictionLabel
                    ) as string)
                  : ''
              }
              description={
                !fetchingApplication
                  ? pages[pageNumber].description(
                      application,
                      localJurisdictionLabel
                    )
                  : ''
              }
              contentsLoading={prefilling || !application || !pages[pageNumber]}
              wideContent={
                !fetchingApplication &&
                pages[pageNumber] == SelectStateApplication
              }
            >
              <Form
                {...{
                  initialValues,
                  validationSchema,
                  onSubmit: onNext
                }}
              >
                <RevalidateOnSchemaChange validationSchema={validationSchema} />
                <PagesManager
                  CurrentPage={pages[pageNumber]}
                  {...{
                    requiresSosRegistration: shouldRenderSOS,
                    hasDynamicStatePage: shouldShowDynamicStatePage,
                    pageMap,
                    handleSkipSOS,
                    handleLocalJurisdictionRegistrations,
                    handleSkipStockTable,
                    handleDynamicStatePage,
                    handleSosPaymentInformation,
                    showSOSPaymentInformation,
                    application,
                    pricingData,
                    saveApplication,
                    onNext,
                    onCancel,
                    updateValidationSchema,
                    pages,
                    pageNumber,
                    firstLocalPageAt,
                    localJurisdictionRegistrations,
                    error,
                    isSubmitting,
                    logo,
                    validationSchema
                  }}
                />
              </Form>
            </Body>
          }
        </>
      </Page>
    </>
  )
}

export default NewRegistrationIntake
