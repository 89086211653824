import React from 'react'
import styled, { css } from 'styled-components'
import SPACING from '../../../System/Spacing'
import { Body } from '../../../System/Typography'
import Link from '../../../System/Link'
import { DepartmentElement } from '../AccountInformation'

const Styles = css`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.small};
`

const EmploymentSecurityCommission = styled(
  ({ className, children }): JSX.Element => {
    return <div {...{ className }}>{children}</div>
  }
)`
  ${Styles}
`

const OklahomaTaxCommission = styled(({ className }) => {
  return (
    <div {...{ className }}>
      <Body>
        1. Navigate to{' '}
        <Link href='https://oktap.tax.ok.gov/oktap/Web/_/' target='_blank'>
          https://oktap.tax.ok.gov/oktap/Web/_/
        </Link>
      </Body>
      <Body>
        2. Log in using your credentials for your Oklahoma DOR account
      </Body>
      <Body>
        3. Click <b>More...</b>
      </Body>
      <Body>
        4. Click <b>Manage Names & Addresses</b>
      </Body>
      <Body>
        5. Select the <b>Addresses</b> tab
      </Body>
      <Body>6. Select the current Mailing address</Body>
      <Body>
        7. Click <b>Change this address</b>
      </Body>
      <Body>
        8. Edit current Mailing address to{' '}
        <b>9450 SW Gemini Dr PMB 73938, Beaverton, OR, 97008</b>
      </Body>
      <Body>
        9. <b>Review & Submit</b>
      </Body>
      <br />
    </div>
  )
})`
  ${Styles}
`

const Oklahoma = (
  departments?: Array<DepartmentElement>
): Array<DepartmentElement> => [
  {
    name: 'Employment Security Commission',
    link: 'https://oklahoma.gov/oesc.html',
    children: (
      <EmploymentSecurityCommission>
        {
          (departments || []).find(
            ({ name }) => name === 'Employment Security Commission'
          )?.children
        }
      </EmploymentSecurityCommission>
    )
  },
  {
    name: '',
    children: [],
    renderMailingAddressCallout: true
  },
  {
    link: 'https://oktap.tax.ok.gov/oktap/Web/_/',
    name: 'Oklahoma Tax Commission',
    children: <OklahomaTaxCommission />,
    renderCompleteLater: true
  }
]

export default Oklahoma
