/* eslint no-irregular-whitespace: 0 */
import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../components/System/Colors'
import Link from '../components/System/Link'

export const TermsStyle = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Suisse Intl';
  padding: 20px;
  margin: 0 auto;
  max-width: 800px;
  color: ${COLORS.karl};

  > hr {
    background-color: ${COLORS.frost};
    border: 0;
    height: 1px;
    margin: 20px 0 10px 0;
    width: 100%;a
  }

  > time {
    color: var(--oc-gray-9);
    font-size: 0.875rem;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  p {
    line-height: 1.5rem;
  }

  ul,
  ol {
    margin: 0;
    padding-left: 20px;
    font-family: 'Suisse Intl';
    font-size: 14px;
  }

  li {
    line-height: 1.5rem;
    margin: 5px 0;
  }

  address {
    font-style: normal;
    line-height: 1.5rem;

    a {
      display: block;
    }
  }
`

const Terms = () => (
  <>
    <title>Middesk | Terms of Use</title>
    <TermsStyle>
      <p>
        If you registered for Middesk Agent Services before February 1, 2024,
        the applicable terms of service can be found{' '}
        <a href='/terms-2021-01-04'>here</a>.
      </p>
      <br />
      <br />

      <h1>Terms of Use</h1>
      <time>Last Updated February 1, 2024</time>
      <hr />
      <p>
        Middesk Agent Services is a product designed to support global
        entrepreneurs by facilitating access to the building blocks of a
        business, including but not limited to: (i) services related to
        corporate registration and qualification, including designating a
        registered agent (“Registered Agent Services”) (collectively, the
        “Registration Services”) and integration of those services (“Integration
        Services”); (ii) customer support regarding the Registration and
        Integration Services (“Customer Support”); and (iii) other services and
        tools, including tools provided by us and by third parties as the
        Middesk program&apos;s network of partners and collaborators grows over
        time (collectively, the “Services”). The third parties described above
        are collectively referred to in these Terms as “Service Providers”.
      </p>
      <h2>General terms</h2>
      <p>
        This Terms of Service (“Terms”) is a contract between the legal entity
        that seeks the Services (“Company”) and Middesk Inc. (“Middesk”, “we”,
        “our”, or “us”). Each Company is a party to this agreement from the time
        it agrees to these Terms. As used in these Terms, the terms “you”,
        “your”, and “user” refer to Company. All Services are provided by us
        through our collaboration with the Service Providers as described either
        in these Terms or in any communications sent to you by Middesk
        (collectively, “Middesk Documentation”). By agreeing to these Terms
        and/or using Services, you acknowledge and agree that the Services are
        for commercial purposes only and you will not use the Services for
        personal, family, or household use. The person agreeing to these Terms
        on your behalf represents that they are the age of majority in their
        respective state or older, that all the information provided to us is
        accurate and complete, and that they are authorized to agree to these
        Terms on the Company&apos;s behalf.
      </p>
      <h2>1. Relationship to Other Agreements</h2>
      <p>
        Certain provisions of the website Terms of Use (available at
        www.middesk.com/terms) and Privacy Policy (available at
        www.middesk.com/privacy) (collectively, the “Website Terms”) are
        incorporated into these Terms by reference. To the extent that there is
        a conflict between the incorporated provisions of the Website Terms and
        the provisions of these Terms, these Terms will prevail.
      </p>
      <p>
        Please note we may update these Terms at any time in our sole discretion
        upon notice to you. For purposes of updates to these Terms, notice shall
        constitute posting updates on your Middesk account, where the Terms
        shall take effect upon the &quot;Last Update&quot; date. You agree to
        periodically review the Terms, and we will alert you to updates by a
        banner notice upon logging into the Services or an email update. By
        continuing to use the Services after the &quot;Last Update&quot; date,
        you agree to be bound by the Terms. If you do not consent to the updated
        Terms, you must immediately stop using the Services.
      </p>
      <h2>2. The Services and Service Providers</h2>
      <p>
        a. <b>Overview</b>: The Services will be provided to you by Middesk, and
        can include Registration Services, Integration Services, Customer
        Support, and any other services that may be provided in partnership with
        Service Providers.
      </p>
      <p>
        b. <b>Information Required</b>: Prior to the commencement of Services,
        Company will provide the necessary information needed to perform the
        Services to Middesk. You represent and warrant to us that all
        information that you provide to us is truthful, accurate, and complete.
        More specifically, you must provide, and we will collect, detailed
        information about your business, that may include but is not limited to:
        (i) details on your product, business model, and flow of funds, (ii)
        personally identifiable information (“PII”) about each of your
        representatives, employees and beneficial owners, and (iii) business
        information for both any newly formed U.S. entity as well as any U.S. or
        foreign parent entity including legal name, address, tax identification
        number, and constituent documents (collectively, “Company Data”). We may
        also require you to upload documents to our website. This may include
        verification of personal identification and beneficial ownership, denied
        persons or other sanction checks, and other efforts to verify the
        legitimacy of business entities and the associated beneficial owners.
        Where practical, we will attempt to verify the information you provide
        using various programmatic and manual methods of review, but Middesk
        carries no obligation or liability relating to the accuracy or
        completeness of any Company Data or other Company information provided
        to Middesk.{' '}
        <b>
          You are responsible for ensuring that all information provided by you
          is accurate and complete.
        </b>{' '}
        Upon review of information you provide to us, or at any time thereafter,
        we reserve the right, in our sole and absolute discretion, to choose not
        to provide any aspect of the Services to you. You will have no recourse,
        and we will have no liability, if upon review of information you provide
        to us, or at any time thereafter, either we or any Service Providers, or
        our partners or collaborators decline to do business with you.
      </p>
      <p>
        c. <b>Registered Agent Services</b>: As part of the Registered Agent
        Services, you authorize the registered agent designated by us from time
        to time to serve as your registered agent (“Registered Agent”). Where
        requested by you, we will notify you of the identity of the Registered
        Agent and, prior to the provision of certain Registration Services, you
        may be required to accept the terms of service and any related
        agreements with the designated Registered Agent. Further, as a
        requirement for your continued use of the Service, we may require that
        you work with a new registered agent. Such terms of service and any
        related agreements will govern the relationship between you and the
        Registered Agent. You are solely and exclusively responsible for
        updating your address, contact, and any other business information with
        the Registered Agent through your Middesk account or by contacting us at{' '}
        <Link href='mailto:support@middesk.com'>support@middesk.com</Link>.
      </p>
      <p>
        If your entity is dissolved, either voluntarily or involuntarily, it is
        your responsibility to notify us of this dissolution so that we may
        inform the Registered Agent of this fact. If you cancel the Registered
        Agent&apos;s appointment, or the appointment is otherwise terminated,
        you will not be entitled to a refund for any prepaid fees, including
        fees for the time period between cancellation or termination and the
        appointment of a new registered agent. If you do not make full payment
        when due, your Registered Agent services may be terminated. You agree to
        bear any risk and under no circumstances will we or the Registered Agent
        be liable or responsible for any damage or inconvenience caused or
        alleged to be caused by termination of the Registered Agent services.
      </p>

      <p>
        d. <b>Additional Authorization</b>: As part of the Services, you
        authorize us to communicate on your behalf, in states in which you do
        business or seek to do business, with the secretary of state, state tax
        authority, or other state regulator, only insofar as such communication
        is necessary to provide the Services.
      </p>
      <p>
        e. <b>New Services</b>: If you access any new or updated features,
        communications, or services through your Middesk account, such use will
        be subject to these Terms unless explicitly stated otherwise.
      </p>
      <p>
        f. <b>Limitations</b>: You acknowledge, understand, and agree that: (i)
        we are not a law firm nor an accounting firm and as such we provide no
        legal, tax or accounting advice of any kind whatsoever, (ii) the Company
        is solely responsible for all corporate, tax, securities and other legal
        reporting, payment and compliance aspects of your personal and business
        activities, (iii) all information, forms, and materials provided through
        the Services are provided to you for informational and educational
        purposes only and do not constitute tax or legal advice, and (iv) none
        of the Middesk Documentation, website, platform, or these Terms are
        intended to create an attorney-client relationship, and if you
        communicate with us then your communication will not be subject to any
        attorney-client privilege. If you require legal advice at any time, you
        should consult with a licensed attorney.
      </p>
      <p>
        g. <b>Compliance with Applicable Law</b>: Various laws, rules and
        regulations apply to the Services (“Applicable Law”), and you agree to
        comply with Applicable Law related to your use of the Services at all
        times, including the sanctions laws of the United States as administered
        by the Office of Foreign Asset Controls, and you must not use the
        Services in connection with any unlawful gambling, unlawful internet
        gambling, or other “restricted transactions” under the federal Unlawful
        Internet Gambling Enforcement Act of 2006 or any state laws.
      </p>
      <h2>3. Use of Company Data and Data Protection; Privacy</h2>
      <p>
        Protecting, securing, and maintaining the information processed and
        handled through the Services is one of our top priorities, and it should
        be yours, too. This section describes our respective obligations when
        handling and storing information connected with the Services.
      </p>
      <p>
        a. <b>General</b>: You consent to our disclosure of the Company Data you
        provide to us including your name and address, and information about the
        transactions that you process through the Services to other Service
        Providers, and allow us to serve as your authorized agent in signing
        documents on behalf of your Company to facilitate providing the Services
        to you. You also consent to our and the Service Providers’ use of
        Company Data, including PII, provided to or collected by us as part of
        providing the Services for internal analyses and fraud monitoring,
        aggregated and anonymized reporting, to verify and re-verify your
        identity, including but not limited to the nature and ownership of your
        business and the risk of doing business with you, in connection with
        improving and training its machine learning algorithms, to supplement
        and improve Middesk&apos;s databases used in connection with its
        Services, and as permitted by Middesk’s Privacy Policy, available at{' '}
        <a href='www.middesk.com/privacy'>www.middesk.com/privacy</a>. In the
        event of a conflict between these Terms and the Middesk Privacy Policy,
        these Terms will control.
      </p>
      <p>
        b. <b>Confidentiality</b>: We will maintain Company Data in our
        possession as your confidential information, and will only use such
        Company Data as permitted by these Terms, or as otherwise directed to or
        consented to by you through an agreement with a third-party service
        provider. You will maintain any data you receive through the Services
        that is not Company Data as our confidential information, may not
        disclose or distribute any such data, and you will only use such data in
        conjunction with the Services for your business use and as permitted by
        these Terms or by other agreements between you and us.
      </p>
      <p>
        c. <b>Privacy</b>: Protection of PII is very important to us. The
        Middesk Privacy Policy explains how and for what purposes we collect,
        use, retain, disclose, and safeguard the PII you provide to us. You
        agree to review the terms of our Privacy Policy, which we update from
        time to time. We may collect additional data about you from Service
        Providers regarding your use of their services to enhance the Services
        we provide to you. The data we may receive varies by provider and is
        controlled by that provider. The collection, sharing and use of data by
        our Service Providers is governed by their respective privacy policies.
        Our use of data we receive about you from Service Providers is governed
        by these Terms and our Privacy Policy. You affirm that you are now and
        will continue to be compliant with all Applicable Law governing privacy
        and your use of Company Data, including PII, that you provide to us or
        access through your use of the Services. You also affirm that you have
        obtained all necessary rights and consents under Applicable Law to
        disclose to us — or allow us to collect, use, retain, and disclose — any
        PII that you provide to us or authorize us to collect, including data
        that we may collect using cookies or other similar means. We will comply
        with our obligations under Applicable Law if we become aware that we
        caused a loss, theft, or breach of PII. If required by applicable law,
        we will also notify you and provide you with sufficient information
        regarding the loss, theft or breach.
      </p>
      <h2>4. Service Fees</h2>
      <p>
        We provide the Services to you at the rates set forth on the
        registration page of your Middesk account unless agreed to otherwise in
        a separate, fully executed order form (“Fees”). In addition to our Fees,
        you are responsible for paying Middesk for all filing fees and costs,
        Registered Agent fees, and any other pass-through fees related to the
        Services. We do not assume any obligation with respect to services
        provided by Service Providers. All fees charged by us will be reflected
        in communications sent to you by us, and you agree that we have the
        right to deduct fees from amounts processed for you, and, to the extent
        applicable, you agree to be bound by the NACHA rules for any such fees
        deducted via the ACH system. Fees for other services (such as for
        processing a payment) are charged separately. We may revise the Fees at
        any time by posting such changes to your Middesk account or emailing you
        an updated order form at the email address provided to us. If you have
        any questions about your Fees, you can email us at support@middesk.com.
      </p>
      <h2>5. Indemnification</h2>
      <p>
        You agree to defend, indemnify, and hold harmless us and the Service
        Providers, as well as the affiliates and respective employees and agents
        of each (each an “Indemnitee”) from and against any claim, suit, demand,
        loss, liability, damage, action, or proceeding arising out of or
        relating to: (i) your breach of any provision of these Terms, (ii) any
        disputes between you and your customers or any other third party, (iii)
        your negligence, recklessness, or wrongful intentional acts, (iv) your
        contractual, or other, obligations between you and any third party,
        including any Service Provider, or (v) your use of the Services in a
        manner that is illegal, violates Applicable Law, or is inconsistent with
        the Middesk Documentation.
      </p>
      <h2>6. Representations and Warranties</h2>
      <p>
        You represent and warrant to us that you will: (i) ensure that you
        comply with your obligations under these Terms, (ii) comply with
        Applicable Law, and (iii) not use the Services in a manner that is
        illegal, or could be considered unfair or deceptive. You may use the
        Services for the purposes described in these Terms and in a manner
        consistent with the Middesk Documentation. Except as expressly stated to
        the contrary, no license, ownership or other interest is transferred
        under these Terms. Except as expressly provided in these Terms, we
        provide the Services to you on an “as is” and “as available” basis,
        without any warranties, express implied, or statutory.
      </p>
      <h2>7. Limitations of Liability</h2>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT WE
        WILL NOT BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL,
        EXEMPLARY OR PUNITIVE DAMAGES REGARDLESS OF THE LEGAL THEORY, OR WHETHER
        WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.UNDER NO
        CIRCUMSTANCES WILL WE BE RESPONSIBLE FOR ANY DAMAGE, LOSS, OR INJURY
        RESULTING FROM HACKING, TAMPERING, OR OTHER UNAUTHORIZED ACCESS OR USE
        OF THE SERVICES OR YOUR ACCOUNT, OR THE INFORMATION CONTAINED THEREIN.
      </p>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT OUR
        TOTAL LIABILITY FOR ALL CLAIMS RELATING TO YOUR USE OF MIDDESK’S
        SERVICES SHALL IN NO EVENT EXCEED THE AMOUNT YOU PAID TO US FOR SERVICES
        DURING THE THREE-MONTH PERIOD PRECEDING THE DATE OF YOUR CLAIM
      </p>
      <h2>8. Other General Legal Terms</h2>
      <p>
        a. <b>Term, Termination, and the Effects of Termination</b>: The term of
        this agreement will begin when you agree to these Terms or commence use
        of the Services (“Effective Date”), and continue for one (1) year
        following the Effective Date (“Initial Term”). Following the Initial
        Term and subject to earlier termination as provided below, your
        agreement will automatically renew for successive terms of one (1) year
        (collectively, the “Term”), unless either Party requests termination at
        least 30 days prior to the end of the then-current term.
      </p>
      <p>
        We may terminate these Terms or suspend your access to some or all of
        the Services (i) immediately, if you are in breach of these Terms or the
        Privacy Policy, (ii) upon 30 days&apos; notice for any reason, (iii) if
        your participation or use of the Services presents, in our sole
        discretion, a heightened risk of legal or regulatory non-compliance or
        an unacceptable risk of harm to Middesk or a Service Provider, (iv) if
        you become the subject of any voluntary or involuntary bankruptcy or
        insolvency petition or proceeding, (v) upon the demand of any other
        Service Provider, (vi) if you fail to provide any information we request
        to verify your identity, the nature and ownership of your business, or
        evaluate the risk of doing business with you, or (vii) if required to do
        so under court or legal order or as required by Applicable Law.
      </p>
      <p>
        Upon termination of this Agreement, you waive and release us from any
        obligation to forward or re-mail documents that are received after the
        date of termination and you specifically agree that we have no
        obligation to take any further action on your behalf in connection with
        any Services rendered prior to the date of termination. You further
        agree to hold us and our affiliates harmless from any third-party claim
        arising out of a delay or failure of you to receive notice, or respond
        to or action a notice due to the termination of this Agreement. Please
        note termination of these Terms will not affect any continuing
        compliance obligations to which you may be subject including corporate
        filing, governance, and taxation obligations. The sections of these
        Terms that by their sense and context are intended to survive
        termination shall survive.
      </p>
      <p>
        b. <b>Dispute Resolution; Agreement to Arbitrate</b>:
      </p>
      <ol>
        <li>
          <b>Binding Arbitration</b>: Any dispute not resolved by the Parties by
          mutual consultation will be determined by arbitration in San
          Francisco, California before a single arbitrator. The arbitration will
          be administered by JAMS. For claims greater than $250,000, the JAMS
          Comprehensive Arbitration Rules and Procedures in effect at the time
          the arbitration is commenced will apply. For claims less than or equal
          to $250,000, the JAMS Streamlined Arbitration Rules in effect at the
          time the arbitration is commenced will apply. The arbitrator will
          apply the substantive law of the State of California, exclusive of its
          conflict or choice of law rules. If JAMS is no longer in business or
          refuses or declines to administer any dispute between the Parties
          brought before it, either Party may petition the United States
          District Court for the Northern District of California to appoint the
          arbitrator. Nothing in this Section will preclude the Parties from
          seeking provisional remedies in aid of arbitration from a court of
          appropriate jurisdiction. The Parties acknowledge that this agreement
          evidences a transaction involving interstate commerce. Notwithstanding
          the provisions in this paragraph referencing applicable substantive
          law, the Federal Arbitration Act (9 U.S.C. §§ 1-16) will govern any
          arbitration conducted pursuant to the terms of this agreement. Either
          Party may commence arbitration by providing to JAMS and the other
          Party to the dispute a written demand for arbitration, setting forth
          the subject of the dispute and the relief requested. The existence of
          a dispute and the observance by the Parties of the dispute resolution
          procedures in this Section will not: (a) excuse any Party from
          continuing to perform its obligations under this agreement; or (b)
          suspend any obligation to pay any amount otherwise due and payable
          under this agreement unless that obligation or the amount (to the
          extent in dispute) is itself the subject of the dispute. Nothing in
          this agreement affects the right of a Party to institute proceedings
          to seek urgent injunctive or declaratory relief in respect of a
          dispute or any matter arising under this agreement. If any dispute
          leads to an arbitration or other legal proceeding to resolve such
          dispute, the prevailing Party in such proceeding will be entitled to
          receive its reasonable attorneys&apos; fees, expert witness fees and
          out-of-pocket costs incurred in connection with such proceeding, in
          addition to any other relief it may be awarded. To the extent
          permitted by applicable law, all arbitration proceedings will be
          subject to the confidentiality terms herein.
        </li>
        <li>
          Either party may commence arbitration by providing JAMS and the other
          party to the dispute a written demand for arbitration, setting forth
          the subject of the dispute and the relief requested.
        </li>
        <li>
          <b>Service of Process</b>: Each party hereby irrevocably and
          unconditionally consents to service of process through personal
          service at their corporate headquarters, registered address, or
          primary address as noted on your Middesk account. Nothing in these
          Terms will affect the right of any party to serve process in any other
          manner permitted by Law.
        </li>
        <li>
          <b>Class Waiver</b>: To the fullest extent permitted by Law, each of
          the parties agrees that any dispute arising out of or in connection
          with these Terms, whether in arbitration or in court, will be
          conducted only on an individual basis and not in a class, consolidated
          or representative action. If for any reason a claim or dispute
          proceeds in court rather than through arbitration, each party
          knowingly and irrevocably waives any right to trial by jury in any
          action, proceeding or counterclaim arising out of or relating to these
          Terms or any of the transactions contemplated between the parties.
        </li>
        <li>
          <b>Provision of an Award</b>: Subject to the limitations of liability
          identified in these Terms, the appointed arbitrators may award
          monetary damages and any other remedies allowed by the laws of the
          State of California. In making a determination, the arbitrator will
          not have the authority to modify any term or provision of these Terms.
          The arbitrator will deliver a reasoned written decision with respect
          to the dispute (the “Award”) to each party, who will promptly act in
          accordance with the Award. Any Award (including interim or final
          remedies) may be confirmed in or enforced by a state or federal court
          located in San Francisco, California. The decision of the arbitrator
          will be final and binding on the parties, and will not be subject to
          appeal or review.
        </li>
        <li>
          <b>Fees</b>: Each party will advance one-half of the fees and expenses
          of the arbitrators, the costs of the attendance of the arbitration
          reporter at the arbitration hearing, and the costs of the arbitration
          facility. In any arbitration arising out of or related to these Terms,
          the arbitrators will award to the prevailing party, if any, the costs
          and attorneys&apos; fees reasonably incurred by the prevailing party
          in connection with those aspects of its claims or defenses on which it
          prevails, and any opposing awards of costs and legal fees awards will
          be offset.
        </li>
        <li>
          <b>Confidentiality</b>: The parties will maintain the confidential
          nature of the arbitration proceeding, the hearing and the Award,
          except (i) as may be necessary to prepare for or conduct the
          arbitration hearing on the merits, (ii) in connection with a court
          application as contemplated above for a preliminary remedy, or
          confirmation of an Award or its enforcement, (iii) our disclosure of
          the Award in confidential settlement negotiations, or (iv) as
          otherwise required by Applicable Law. The parties, witnesses, and
          arbitrator will treat as confidential and will not disclose to any
          third person (other than witnesses or experts) any documentary or
          other evidence produced in any arbitration hereunder, except as
          required by Law or except if such evidence was obtained from the
          public domain or was otherwise obtained independently from the
          arbitration.
        </li>
        <li>
          <b>Conflict of Rules</b>: In the case of a conflict between the
          provisions of this Section 8(b)(viii) and the rules governing
          arbitration identified in Section 8(b)(i), the provisions of this
          Section 8(b)(viii) will prevail. If any provision of these Terms to
          arbitrate is held invalid or unenforceable, it will be so held to the
          minimum extent required by Law and all the other provisions will
          remain valid and enforceable.
        </li>
      </ol>
      <p>
        c. <b>Right to Amend</b>: We may amend these Terms upon notice to you,
        which may be provided through your Middesk account or email. You agree
        that any changes to these Terms will be binding on you upon notice or
        your use of the Services. If you elect to not accept any changes to
        these Terms, you must cease using the Services and provide notice to us
        at support@middesk.com. Failure to provide such notice or any continued
        use of the Services shall be deemed to be your affirmative consent to
        such changes.
      </p>
      <p>
        d. <b>Assignment</b>: You may not assign this Agreement, any rights or
        licenses granted in these Terms, or the right to receive any Services to
        others without our prior written consent. If you wish to make such an
        assignment, please contact us. If we consent to the assignment, the
        assignee agrees to assume all of your rights and obligations owed by you
        related to the assignment, and must agree to comply with the terms of
        these Terms. We may assign this Agreement without your consent or any
        other restriction. If we make an assignment, we will provide reasonable
        notice to you.
      </p>
      <p>
        e. <b>No Third-Party Beneficiaries</b>: these Terms are not intended to
        and shall not be construed to give any third party any interest or
        rights (including any third party beneficiary rights) concerning or in
        connection with any agreement or provision contained herein or
        contemplated hereby.
      </p>
      <p>
        f. <b>Notices</b>: All notices under this Agreement will be in writing
        sent to the mailing and/or email addresses associated with your Middesk
        account and will be deemed to have been duly given when received, if
        personally delivered; when receipt is electronically confirmed, if
        transmitted by e-mail; the day after it is sent, if sent for next day
        delivery by recognized overnight delivery service; and upon receipt, if
        sent by certified or registered mail, return receipt requested.
      </p>
      <p>
        g. <b>Entire Agreement</b>: These Terms constitutes the entire agreement
        between you and us with respect to the Services. These Terms describes
        our entire liability to you and sets forth your exclusive remedies with
        respect to the Services. If any part of these Terms is held to be
        invalid or unenforceable under Applicable Law, then it should be changed
        and interpreted to accomplish the objectives of such provision to the
        greatest extent possible under Applicable Law, and the remaining
        provisions will continue in full force and effect.
      </p>
      <p>
        h. <b>Construction; Waiver</b>: No provision of these Terms will be
        construed against any party on the basis of that party being the
        drafter. Unless expressly stated otherwise, the use of the term
        “including” or “such as” is not to be interpreted as limiting the
        generality of the text preceding the term. The failure of either party
        to enforce any provision of these Terms will not constitute a waiver of
        that party&apos;s rights to subsequently enforce the provision.
      </p>
    </TermsStyle>
  </>
)

export default Terms
