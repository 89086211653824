import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'
import Env from './lib/env'

if (!Env.isDevelopment()) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN || '',
    integrations: [
      new Sentry.Replay({
        blockAllMedia: true,
        maskAllText: true
      })
    ],
    release: process.env.REACT_APP_GIT_REV || 'dev',
    replaysOnErrorSampleRate: 1,
    replaysSessionSampleRate: 0
  })
}

FullStory.init({
  orgId: process.env.REACT_APP_FULLSTORY_ORG_ID || 'undefined',
  devMode: Env.isDevelopment()
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
