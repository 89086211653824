import React from 'react'
import styled, { css } from 'styled-components'
import SPACING from '../../../System/Spacing'
import { Body } from '../../../System/Typography'
import Link from '../../../System/Link'
import { DepartmentElement } from '../AccountInformation'

const Styles = css`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.small};
`

const StateTaxCommission = styled(
  ({ className, children }): JSX.Element => {
    return <div {...{ className }}>{children}</div>
  }
)`
  ${Styles}
`

const DepartmentOfLabor = styled(({ className }) => {
  return (
    <div {...{ className }}>
      <Body>
        1. Navigate to{' '}
        <Link href='https://jobs.utah.gov/' target='_blank'>
          https://jobs.utah.gov/
        </Link>
      </Body>
      <Body>
        2. Log in using your credentials for the Unemployment Compensation
        Management System
      </Body>
      <Body>
        3. Select <b>Employers</b> tab
      </Body>
      <Body>
        4. Select<b>Manage Unemployment Account</b>
      </Body>
      <Body>
        5. Scroll down to <b>Account Admin</b>
      </Body>
      <Body>
        6. Select <b>Update address information</b>
      </Body>
      <Body>
        7. Next to your current mailing address select <b>Edit</b>
      </Body>
      <Body>
        8. Update the mailing address to{' '}
        <b>9450 SW Gemini Dr PMB 73938, Beaverton, OR, 97008</b>
      </Body>
      <Body>
        9. Select <b>Submit</b>
      </Body>
    </div>
  )
})`
  ${Styles}
`

const Utah = (
  departments?: Array<DepartmentElement>
): Array<DepartmentElement> => [
  {
    name: 'State Tax Commission',
    link: 'https://tax.utah.gov/',
    children: (
      <StateTaxCommission>
        {
          (departments || []).find(
            ({ name }) => name === 'State Tax Commission'
          )?.children
        }
      </StateTaxCommission>
    )
  },
  {
    name: '',
    children: [],
    renderMailingAddressCallout: true
  },
  {
    name: 'Department of Labor',
    link: 'https://jobs.utah.gov/',
    children: <DepartmentOfLabor />,
    renderCompleteLater: true
  }
]

export default Utah
