import React, { createContext, useContext, useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'
import { isEmpty } from 'lodash'
import { Department, QuestionsCollection, TransferQuestion } from '../types'
import { AuthContext } from './AuthProvider'
import APIQuestions from '../lib/api/questions'
import { PackageTypes } from '../lib/constants'
import API from '../lib/api'
import { STATE_ABBR_ARRAY } from '../lib/constants'

interface StateDataProps {
  stateData: Array<Record<string, any>>
  stateDataMap: Record<string, any>
  agencyData: Array<any>
  stateAgencyData: Array<any>
  transferDepartmentsMap: Record<string, Department[]>
  fetching: boolean
  requiresForeignQualification: (
    state: string,
    questions: Record<string, any>
  ) => boolean
}

export const StateDataContext = createContext<StateDataProps>({
  stateData: [],
  stateDataMap: {},
  agencyData: [],
  stateAgencyData: [],
  transferDepartmentsMap: {},
  fetching: false,
  requiresForeignQualification: () => false
})

export const StateDataProvider = ({
  children
}: {
  children: any
}): JSX.Element => {
  const [stateData, setStateData] = useState([])
  const [stateDataMap, setStateDataMap] = useState<Record<string, any>>({})
  const [agencyData, setAgencyData] = useState([])
  const [stateAgencyData, setStateAgencyData] = useState([])
  const [fetchingStates, setFetchingStates] = useState(true)
  const [fetchingAgencies, setFetchingAgencies] = useState(true)
  const [fetchingStateAgencies, setFetchingStateAgencies] = useState(true)
  const [transferDepartmentsMap, setTransferDepartmentsMap] = useState<
    Record<string, Department[]>
  >({})
  const [fetchingDepartments, setFetchingDepartments] = useState(true)
  const { authenticated, transactionalAccount, account } = useContext(
    AuthContext
  )
  const requiresForeignQualification = (
    state: string,
    questions: Record<string, any>
  ) => {
    const packageType = account.settings.agent.package_type
    if (
      !transactionalAccount &&
      [PackageTypes.premium, PackageTypes.unlimited].includes(packageType)
    ) {
      return true
    }

    if (!stateDataMap[state]) {
      return false
    }

    const stateData = stateDataMap[state].tax_registration

    if (!stateData.requires_foreign_qualification) {
      return false
    }

    if (stateData.skip_foreign_qualification) {
      const skipQuestionValue = (questions[state] || {})[
        stateData.skip_foreign_qualification.question
      ]

      if (
        skipQuestionValue ==
        stateData.skip_foreign_qualification.value.toString()
      )
        return false
    }

    return true
  }

  useEffect(() => {
    if ((!isEmpty(stateData) && !isEmpty(agencyData)) || !authenticated) {
      return
    }
    setFetchingStates(true)
    API.get('/ajax/agent/states')
      .then(json => {
        setStateData(json['data'])
        const map: any = {}
        json['data'].forEach((s: any) => (map[s.abbr] = s))
        setStateDataMap(map)
      })
      /* eslint-disable-next-line no-console */
      .catch(err => console.error(err))
      .finally(() => setFetchingStates(false))

    setFetchingAgencies(true)
    API.get('/ajax/agent/agencies')
      .then(json => {
        setAgencyData(json['data'])
      })
      /* eslint-disable-next-line no-console */
      .catch(err => console.error(err))
      .finally(() => setFetchingAgencies(false))

    API.get('/ajax/agent/states?with_agencies=true')
      .then(json => {
        setStateAgencyData(json['data'])
      })
      /* eslint-disable-next-line no-console */
      .catch(err => console.error(err))
      .finally(() => setFetchingStateAgencies(false))
  }, [])

  useEffect(() => {
    if (!isEmpty(transferDepartmentsMap) || !authenticated) {
      return
    }
    setFetchingDepartments(true)
    APIQuestions.get({
      states: STATE_ABBR_ARRAY,
      categories: ['transfer']
    })
      .then(({ data = [] }: QuestionsCollection) => {
        const deptMap: Record<string, Department[]> = {}
        data.forEach(({ state, questions }) => {
          const transferQuestions = questions as TransferQuestion[]
          if (isEmpty(questions)) {
            return
          }
          const departments: Department[] = []
          transferQuestions.forEach(question => {
            if (question.department === undefined) {
              return
            }
            if (
              departments.find(
                department => department.name === question.department
              ) !== undefined
            ) {
              departments
                .find(department => department.name === question.department)
                ?.inputs.push(question)
            } else {
              departments.push({
                name: question.department,
                inputs: [question]
              } as Department)
            }
          })
          deptMap[state] = departments
        })
        setTransferDepartmentsMap(deptMap)
      })
      .catch(e => Sentry.captureException(e))
      .finally(() => setFetchingDepartments(false))
  }, [])

  return (
    <StateDataContext.Provider
      value={{
        stateData,
        stateDataMap,
        agencyData,
        stateAgencyData,
        transferDepartmentsMap,
        fetching:
          fetchingStates ||
          fetchingAgencies ||
          fetchingDepartments ||
          fetchingStateAgencies,
        requiresForeignQualification
      }}
    >
      {children}
    </StateDataContext.Provider>
  )
}
