import React from 'react'
import styled, { css } from 'styled-components'
import SPACING from '../../../System/Spacing'
import { Body } from '../../../System/Typography'
import Link from '../../../System/Link'
import { DepartmentElement } from '../AccountInformation'

const Styles = css`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.small};
`

const EmployerTaxRate = styled(
  ({ className, children }): JSX.Element => {
    return <div {...{ className }}>{children}</div>
  }
)`
  ${Styles}
`

const DepartmentOfRevenue = styled(({ className }) => {
  return (
    <div {...{ className }}>
      <Body>
        1. Navigate to{' '}
        <Link href='https://intime.dor.in.gov/eServices/_/#1' target='_blank'>
          https://intime.dor.in.gov/eServices/_/#1
        </Link>
      </Body>
      <Body>2. Log in using your credentials for your Indiana DOR account</Body>
      <Body>
        3. Select the <b>All Actions</b> tab
      </Body>
      <Body>
        4. Click <b>Manage names & addresses</b>
      </Body>
      <Body>
        5. Select the <b>Addresses</b> tab
      </Body>
      <Body>
        6. Edit current Mailing address to&nbsp;
        <b>9450 SW Gemini Dr PMB 73938, Beaverton, OR, 97008</b>{' '}
      </Body>
      <Body>
        7. Select <b>Next</b>
      </Body>
      <Body>
        8. Select <b>Submit</b>
      </Body>
      <br />
    </div>
  )
})`
  ${Styles}
`

const Indiana = (
  departments?: Array<DepartmentElement>
): Array<DepartmentElement> => [
  {
    name: 'Department of Workforce Development',
    link: 'https://www.in.gov/dwd/',
    children: (
      <EmployerTaxRate>
        {
          (departments || []).find(
            ({ name }) => name === 'Department of Workforce Development'
          )?.children
        }
      </EmployerTaxRate>
    )
  },

  {
    name: '',
    children: [],
    renderMailingAddressCallout: true
  },
  {
    link: 'https://intime.dor.in.gov/eServices/_/#1',
    name: 'Department of Revenue',
    children: <DepartmentOfRevenue />,
    renderCompleteLater: true
  }
]

export default Indiana
