import React, { useEffect, useState } from 'react'
import { SelectField, TextField } from '@middesk/components'
import { useFormikContext } from 'formik'
import * as yup from 'yup'
import NewRegistrationIntakeFooter from '../components/NewRegistrationIntakeFooter'
import { Error, Page, PageProps, GapWrapper } from '../components/Page'
import Address from '../components/Address'
import { FormValues } from '../types'
import { StyledSelectWrapper } from './CompanyDetails'
import {
  REGISTRATION_REASONS,
  ACQUIRED_REGISTERED,
  CHANGED_ENTITY_TYPE,
  ACCOUNTING_BASIS_OPTIONS,
  PAYROLL_FREQUENCY_OPTIONS,
  ADDRESS_TYPES
} from '../lib/constants'
import moment from 'moment'

const validationSchema = yup.object().shape({
  previous_entity_type_change_date: yup
    .mixed()
    .when('registration_reason', {
      is: CHANGED_ENTITY_TYPE,
      then: yup
        .date()
        .typeError('Invalid date format')
        .required('Please enter date entity type changed')
    })
    .when('registration_reason', {
      is: registration_reason => registration_reason !== CHANGED_ENTITY_TYPE,
      then: yup.string().optional().nullable()
    })
    .nullable(),
  previous_entity_type_fein: yup.string().optional().nullable(),
  previous_entity_type_state_jurisdiction_ein: yup
    .string()
    .optional()
    .nullable(),
  fiscal_year_end: yup
    .date()
    .typeError('Invalid date format')
    .required('Please enter fiscal year end')
    .nullable()
    .test(
      'year',
      'Must be in current year',
      value => !!value && moment(value).year() === moment().year()
    ),
  futa_year: yup
    .string()
    .nullable()
    .required(
      'Please indicate if and when the company has ever been deemed liable for FUTA'
    ),
  registration_reason: yup
    .string()
    .required('Please indicate why you are registering in state')
    .nullable(),
  acquisition_date: yup
    .mixed()
    .when('registration_reason', {
      is: ACQUIRED_REGISTERED,
      then: yup
        .date()
        .typeError('Invalid date format')
        .required('Please enter acquisition date')
    })
    .when('registration_reason', {
      is: registration_reason => registration_reason !== ACQUIRED_REGISTERED,
      then: yup.string().optional().nullable()
    })
    .nullable(),
  acquisition_legal_name: yup
    .string()
    .when('registration_reason', {
      is: ACQUIRED_REGISTERED,
      then: yup
        .string()
        .required('Please provide name of company before acquisition')
    })
    .when('registration_reason', {
      is: registration_reason => registration_reason !== ACQUIRED_REGISTERED,
      then: yup.string().optional().nullable()
    })
    .nullable(),
  acquisition_fein: yup
    .string()
    .when('registration_reason', {
      is: ACQUIRED_REGISTERED,
      then: yup
        .string()
        .test(
          'len',
          'FEIN must be 9 characters',
          value => !!value && value.replace(/\D+/g, '').length === 9
        )
        .required('Please enter FEIN of acquired company')
    })
    .when('registration_reason', {
      is: registration_reason => registration_reason !== ACQUIRED_REGISTERED,
      then: yup.string().optional().nullable()
    })
    .nullable(),
  acquisition_state_jurisdiction_ein: yup.string().optional().nullable(),
  accounting_basis: yup
    .string()
    .required('Please indicate the accounting basis for the company')
    .nullable(),
  payroll_frequency: yup
    .string()
    .required('Please indicate the payroll frequency for the company')
    .nullable(),
  previous_primary_address: yup.object().shape({
    address_line1: yup.string().optional().nullable(),
    address_line2: yup.string().optional().nullable(),
    city: yup.string().optional().nullable(),
    state: yup.string().optional().nullable(),
    postal_code: yup.string().optional().nullable(),
    type: yup.string().optional()
  })
})

const AdditionalCompanyDetails: Page = ({
  onNext,
  onCancel,
  updateValidationSchema,
  isSubmitting,
  error,
  logo,
  progress
}: PageProps) => {
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const defaultFutaEligibility = isNaN(Number(values.futa_year))
    ? ''
    : String(values.futa_year) === '0'
    ? 'No'
    : 'Yes'
  const [futaEligible, setFutaEligible] = useState(defaultFutaEligibility)
  const nonprofit501c3 =
    String(values.non_profit) === 'true' &&
    String(values.is_501c3_organization) === 'true'

  useEffect(() => {
    updateValidationSchema && updateValidationSchema(validationSchema)

    if (nonprofit501c3) {
      setFieldValue('futa_year', '0')
    }
  }, [])

  const maxYear = new Date().getFullYear() + 1
  const formationYear = values.formation_date?.split('/')[2]
  const futaYears = []
  if (formationYear == undefined) {
    futaYears.push(
      maxYear.toString(),
      (maxYear - 1).toString(),
      (maxYear - 2).toString(),
      (maxYear - 3).toString(),
      (maxYear - 4).toString()
    )
  } else {
    for (let i = parseInt(formationYear); i <= maxYear; i++) {
      futaYears.push(i.toString())
    }
  }

  const updateFutaEligible = (e: { value: string }) => {
    setFutaEligible(e.value)
    setFieldValue('futa_year', e.value === 'No' ? '0' : undefined)
  }

  return (
    <>
      <GapWrapper>
        <TextField
          label='Fiscal year end date in current year'
          placeholder='MM/DD/YYYY'
          type='date'
          name='fiscal_year_end'
        />
        {!nonprofit501c3 && (
          <>
            <StyledSelectWrapper>
              <SelectField
                onChange={updateFutaEligible}
                label='Are you liable to pay Federal unemployment tax?'
                sublabel='FUTA liability is met if: You paid wages of $1,500 or more to employees in any calendar quarter, OR you had one or more employees for at least some part of a day in any 20 or more different weeks during the year. Count all full-time, part-time, and temporary employees.'
              >
                <option
                  key='futa-yes'
                  value='Yes'
                  label='Yes'
                  selected={futaEligible === 'Yes'}
                />
                <option
                  key='futa-no'
                  value='No'
                  label='No'
                  selected={futaEligible === 'No'}
                />
              </SelectField>
            </StyledSelectWrapper>
            {futaEligible === 'Yes' && (
              <StyledSelectWrapper>
                <SelectField
                  name='futa_year'
                  label='When did your FUTA liability begin?'
                >
                  {futaYears.map(year => (
                    <option
                      key={year}
                      value={year}
                      label={year}
                      selected={String(values.futa_year) === year}
                    />
                  ))}
                </SelectField>
              </StyledSelectWrapper>
            )}
          </>
        )}
        <StyledSelectWrapper>
          <SelectField
            name='registration_reason'
            label='Reason for registration'
          >
            {Object.keys(REGISTRATION_REASONS).map(k => (
              <option
                key={k}
                value={k}
                label={REGISTRATION_REASONS[k]}
                selected={values.registration_reason === k}
              />
            ))}
          </SelectField>
        </StyledSelectWrapper>
        {values.registration_reason === CHANGED_ENTITY_TYPE && (
          <>
            <TextField
              label='Entity type change date'
              placeholder='MM/DD/YYYY'
              type='date'
              name='previous_entity_type_change_date'
            />
            <TextField
              label='Federal Employer Identification Number of previous entity (optional)'
              placeholder='XX-XXXXXXX'
              name='previous_entity_type_fein'
              type='ein'
            />
            <TextField
              label='State employer identification number of previous entity (optional)'
              name='previous_entity_type_state_jurisdiction_ein'
            />
          </>
        )}
        {values.registration_reason === ACQUIRED_REGISTERED && (
          <>
            <TextField
              label='Acquisition date'
              placeholder='MM/DD/YYYY'
              type='date'
              name='acquisition_date'
            />
            <TextField
              label='Legal name of acquired company'
              placeholder='Enter the legal name of the company before acquisition'
              name='acquisition_legal_name'
            />
            <TextField
              label='Federal Employer Identification Number of acquired company'
              placeholder='XX-XXXXXXX'
              name='acquisition_fein'
              type='ein'
            />
            <TextField
              label='State employer identification number of acquired company (optional)'
              name='acquisition_state_jurisdiction_ein'
            />
            <Address
              name='previous_primary_address'
              label='Previous business address (optional)'
              addressType={ADDRESS_TYPES.previous_primary}
              allowPOBox={false}
            />
          </>
        )}
        <StyledSelectWrapper>
          <SelectField name='accounting_basis' label='Accounting basis'>
            {Object.keys(ACCOUNTING_BASIS_OPTIONS).map(k => (
              <option
                key={k}
                value={k}
                label={ACCOUNTING_BASIS_OPTIONS[k]}
                selected={values.accounting_basis === k}
              />
            ))}
          </SelectField>
        </StyledSelectWrapper>
        <StyledSelectWrapper>
          <SelectField name='payroll_frequency' label='Payroll frequency'>
            {Object.keys(PAYROLL_FREQUENCY_OPTIONS).map(k => (
              <option
                key={k}
                value={k}
                label={PAYROLL_FREQUENCY_OPTIONS[k]}
                selected={values.payroll_frequency === k}
              />
            ))}
          </SelectField>
        </StyledSelectWrapper>
      </GapWrapper>
      <Error validationSchema={validationSchema} />
      <NewRegistrationIntakeFooter
        {...{
          values,
          onNext,
          onCancel,
          isSubmitting,
          error,
          isDisabled: !validationSchema.isValidSync(values),
          onClick: () => onNext(values),
          progress,
          title: 'Business information',
          logo
        }}
      />
    </>
  )
}

AdditionalCompanyDetails.pageName = 'AdditionalCompanyDetails'
AdditionalCompanyDetails.title = () => 'Additional Company Details'
AdditionalCompanyDetails.description = () =>
  'Please review the information below and edit if required.'

export default AdditionalCompanyDetails
