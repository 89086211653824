import React, { useContext, useEffect, useMemo } from 'react'
import amplitude from 'amplitude-js'
import { useFormikContext } from 'formik'
import ReactTooltip from 'react-tooltip'
import {
  AgentApplication,
  FormValues,
  LocalRegistrationPageProps,
  TransactionalPricingData
} from '../types'
import { COLORS } from './System/Colors'
import { ApplicationContext } from '../contexts/ApplicationProvider'
import { Page } from './Page'
import * as yup from 'yup'
import { getTaxTypesByIntent, localJurisdictions } from '../lib/helpers'
import { isEqual } from 'lodash'

type PagesManagerProps = {
  CurrentPage: Page
  handleSkipStockTable: (values: FormValues) => void
  handleDynamicStatePage: (values: FormValues) => void
  handleSkipSOS: (values: FormValues) => void
  handleLocalJurisdictionRegistrations: (values: FormValues) => void
  application: AgentApplication
  onNext: (values: FormValues, submit?: boolean) => void
  onCancel: () => void
  updateValidationSchema: (schema: any) => void
  pages: Page[]
  pageNumber: number
  firstLocalPageAt?: number
  localJurisdictionRegistrations?: LocalRegistrationPageProps[]
  error: string | null
  isSubmitting: boolean
  handleSosPaymentInformation: (values: FormValues) => void
  showSOSPaymentInformation: boolean
  pricingData: TransactionalPricingData | undefined
  requiresSosRegistration: boolean
  hasDynamicStatePage: boolean
  pageMap: any
  logo: string | undefined
  saveApplication: (values: any, submit: boolean) => void
  validationSchema: yup.ObjectSchema
}

export const PagesManager = ({
  CurrentPage,
  handleSkipStockTable,
  handleDynamicStatePage,
  handleSkipSOS,
  handleLocalJurisdictionRegistrations,
  handleSosPaymentInformation,
  showSOSPaymentInformation,
  application,
  pricingData,
  requiresSosRegistration,
  hasDynamicStatePage,
  pageMap,
  onNext,
  onCancel,
  updateValidationSchema,
  pages,
  pageNumber,
  firstLocalPageAt,
  localJurisdictionRegistrations,
  error,
  isSubmitting,
  logo,
  saveApplication,
  validationSchema
}: PagesManagerProps) => {
  const { values } = useFormikContext<FormValues>()
  const {
    state,
    localJurisdictionsInState,
    taxTypesByIntent,
    setTaxTypesByIntent,
    setLocalJurisdictionSlugs
  } = useContext(ApplicationContext)

  useEffect(() => {
    handleSkipStockTable(values)
  }, [values?.entity_type, state])

  useEffect(() => {
    handleDynamicStatePage(values)
  }, [values?.jurisdictions])

  useEffect(() => {
    handleSkipSOS(values)
  }, [values?.questions, state])

  useEffect(() => {
    handleLocalJurisdictionRegistrations(values)
  }, [values?.jurisdictions, localJurisdictionsInState])

  useEffect(() => {
    const locals = localJurisdictions(values.jurisdictions)
    if (locals.length === 0) return

    setLocalJurisdictionSlugs(locals)
  }, [values?.jurisdictions])

  useEffect(() => {
    handleSosPaymentInformation(values)
  }, [values?.sos_registration_selection])

  useEffect(() => {
    const selectionsChanged = !isEqual(
      getTaxTypesByIntent(values),
      taxTypesByIntent
    )

    if (selectionsChanged) {
      setTaxTypesByIntent(getTaxTypesByIntent(values))
    }
  }, [values.tax_registrations[0]])

  useMemo(() => {
    amplitude
      .getInstance()
      .logEvent('page-visited', { page: pages[pageNumber].pageName })
  }, [pageNumber])

  const progress = ((pageNumber / pages.length) * 100.0).toPrecision(2)

  return (
    <>
      <CurrentPage
        application={application}
        pricingData={pricingData}
        requiresSosRegistration={requiresSosRegistration}
        hasDynamicStatePage={hasDynamicStatePage}
        pageMap={pageMap}
        showSOSPaymentInformation={showSOSPaymentInformation}
        onNext={onNext}
        onCancel={onCancel}
        updateValidationSchema={updateValidationSchema}
        error={error}
        isSubmitting={isSubmitting}
        logo={logo}
        progress={progress}
        pageNumber={pageNumber}
        firstLocalPageAt={firstLocalPageAt}
        localJurisdictionRegistrations={localJurisdictionRegistrations}
        saveApplication={saveApplication}
        validationSchema={validationSchema}
      />
      <ReactTooltip
        textColor={COLORS.white}
        backgroundColor={COLORS.graphite}
        border
        borderColor={COLORS.dawn}
      />
    </>
  )
}
