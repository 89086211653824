import React, { useContext, useEffect, useState } from 'react'
import { SelectField, theme } from '@middesk/components'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { isEmpty, uniq } from 'lodash'
import { Col, Row } from 'react-styled-flexboxgrid'
import { useHistory } from 'react-router-dom'

import { Page, PageProps } from '../../components/Page'
import Link from '../../components/System/Link'
import NewRegistrationIntakeFooter from '../../components/NewRegistrationIntakeFooter'
import UnmanagedItemsIntake from '../../components/UnmanagedItemsIntake'
import { AuthContext } from '../../contexts/AuthProvider'
import { StateDataContext } from '../../contexts/StateDataProvider'
import { ApplicationContext } from '../../contexts/ApplicationProvider'
import { FormValues, Agency, AvailableAccountStateMap } from '../../types'
import { WIDE_BODY_MOBILE_WIDTH } from '../../components/Body'
import {
  BOTH_SUI_AND_SWH_TYPE,
  MANAGED_BY_MIDDESK,
  STATE_PAYROLL_ACCOUNT
} from '../../lib/constants'
import { comprehensiveTaxTypes, localJurisdictions } from '../../lib/helpers'
import APIAgentApplication from '../../lib/api/agentApplication'
import APICompany from '../../lib/api/company'
import { StyledSelectWrapper } from '../CompanyDetails'
import StateAccountDropdown from './StateAccountDropdown'
import InfoTooltip from './InfoTooltip'

const { spacing, colors } = theme

const StateSelectorPageWrapper = styled.div`
  margin-top: ${spacing.large};
`

const LeftCol = styled(Col)`
  margin-right: ${spacing.large};
`

const EmptyWrapper = styled.div`
  min-height: 40px;
  color: ${colors.karlLight1};
`

const DeleteWrapper = styled.div`
  display: flex;
  justify-content: left;
  margin-top: ${spacing.large};
`

const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;

  > div {
    margin-top: 0;
  }

  @media (max-width: ${WIDE_BODY_MOBILE_WIDTH}) {
    flex-direction: column-reverse;
    gap: ${spacing.compact};
  }
`

const SelectStateApplication: Page = ({
  onNext,
  application,
  updateValidationSchema,
  isSubmitting,
  error,
  pricingData,
  logo,
  progress,
  validationSchema
}: PageProps) => {
  const { user, inGuestMode, transactionalAccount } = useContext(AuthContext)
  const { setState, state, taxTypesByIntent } = useContext(ApplicationContext)
  const {
    stateData,
    stateAgencyData,
    requiresForeignQualification
  } = useContext(StateDataContext)
  const { values, setFieldValue } = useFormikContext<FormValues>()

  const [
    availableStatesMap,
    setAvailableStatesMap
  ] = useState<AvailableAccountStateMap>({})

  const history = useHistory()
  const { push } = history

  const initialStateValue =
    values.tax_registrations.length > 0 ? values.tax_registrations[0].state : ''

  const lockedState = application?.application_invitation?.state

  useEffect(() => {
    setState(initialStateValue)
  }, [initialStateValue])

  useEffect(() => {
    // Redirect any non-transactional users without a package selected on the account to /select-package
    if (
      user &&
      !user?.account.settings.agent.package_type &&
      !transactionalAccount
    ) {
      push('/select-package')
    }
  }, [user])

  useEffect(() => {
    values.foreign_qualifications.pop()
    if (requiresForeignQualification(state, values.questions)) {
      values.foreign_qualifications.push({ state })
    }
  }, [])

  useEffect(() => {
    if (application?.application_invitation && stateAgencyData) {
      const requestedStateData = stateAgencyData.find(
        ({ abbr }) => abbr === application.application_invitation?.state || ''
      )

      if (!requestedStateData) {
        return
      }

      const {
        tax_registration: { requires_sui_and_swh_bundled: bundleRequired }
      } = requestedStateData

      const requestedLocalJurisdictions =
        (application.saved_agency_registrations &&
          application.saved_agency_registrations[0] &&
          application.saved_agency_registrations[0].jurisdictions) ||
        []

      const appTypes = application.tax_registration_types || []
      const requestedStateJurisdictions =
        appTypes.length > 0
          ? bundleRequired
            ? [BOTH_SUI_AND_SWH_TYPE]
            : appTypes
          : []

      setFieldValue('jurisdictions', [
        ...requestedStateJurisdictions,
        ...requestedLocalJurisdictions
      ])
    }
  }, [application?.application_invitation, stateAgencyData])

  useEffect(() => {
    if (!application?.company_id) {
      return
    }

    APICompany.available_accounts(application.company_id).then(({ data }) =>
      setAvailableStatesMap(data)
    )
  }, [application?.company_id])

  const onSelect = (e: { value: string }) => {
    setFieldValue('tax_registrations', [{ state: e.value }])

    if (requiresForeignQualification(e.value, values.questions)) {
      setFieldValue('foreign_qualifications', [{ state: e.value }])
    } else {
      setFieldValue('foreign_qualifications', [])
    }

    delete values.questions[e.value]
    setState(e.value)

    // Select state jurisdictions by default if there are no locals
    const {
      tax_registration: {
        requires_sui_and_swh_bundled: bundleRequired,
        agencies
      }
    } = stateAgencyData.find(({ abbr }) => abbr === e.value)

    if (
      availableStatesMap[e.value].length === 1 &&
      availableStatesMap[e.value].includes(STATE_PAYROLL_ACCOUNT)
    ) {
      if (bundleRequired) {
        setFieldValue('jurisdictions', [BOTH_SUI_AND_SWH_TYPE])
      } else {
        const allTypes: string[] = agencies.map((a: Agency) => a.tax_type)
        setFieldValue('jurisdictions', uniq(allTypes))
      }
    } else {
      setFieldValue('jurisdictions', [])
    }

    const state_addresses_count = (values.secondary_addresses[e.value] || [])
      .length
    setFieldValue('has_state_locations', state_addresses_count > 0)
    if (state_addresses_count > 0) {
      setFieldValue('state_locations_count', state_addresses_count)
    }
  }

  const isSubmitPage =
    (values.jurisdictions || []).length > 0 &&
    isEmpty(taxTypesByIntent[MANAGED_BY_MIDDESK]) &&
    localJurisdictions(values.jurisdictions).length === 0

  const onClick = () => {
    onNext(values, isSubmitPage)
  }

  const onDeleteApp = async () => {
    if (application?.id) {
      await APIAgentApplication.delete(application.id)
        /* eslint-disable-next-line no-console */
        .catch((error: unknown) => console.error(error))
        .finally(() => push('/home'))
    }
  }

  const availableStatesData = stateData.filter(
    data => availableStatesMap[data['abbr']]
  )

  const selectedStateValue = values.tax_registrations[0].state
  const selectedState = availableStatesData.find(
    data => data['abbr'] === selectedStateValue
  )
  const selectedStateLabel = selectedState ? selectedState['name'] : ''

  const isDisabled =
    !state ||
    (values.jurisdictions || []).length < 1 ||
    !validationSchema.isValidSync(values)

  return (
    <StateSelectorPageWrapper>
      <Row>
        <LeftCol xs>
          <StyledSelectWrapper>
            <SelectField
              placeholder='Select a state'
              name='tax_registrations[0].state'
              value={
                values.tax_registrations[0].state
                  ? {
                      value: selectedStateValue,
                      label: selectedStateLabel
                    }
                  : null
              }
              onChange={onSelect}
              label='State'
              disabled={!!lockedState}
            >
              {availableStatesData.map(({ abbr, name }) => {
                return (
                  <option
                    key={abbr}
                    value={abbr}
                    label={name}
                    selected={abbr === state}
                  />
                )
              })}
            </SelectField>
          </StyledSelectWrapper>
          {state && transactionalAccount && (
            <StateAccountDropdown
              state={state}
              availableStatesMap={availableStatesMap}
              lockedState={!!lockedState}
            />
          )}
          {!inGuestMode && !lockedState && (
            <DeleteWrapper>
              <Link {...{ onClick: onDeleteApp }}>Delete Filing</Link>
            </DeleteWrapper>
          )}
        </LeftCol>

        <Col xs>
          {state && (
            <UnmanagedItemsIntake
              selectedJurisdictions={values.jurisdictions || []}
              pricingData={transactionalAccount ? pricingData : undefined}
              updateValidationSchema={updateValidationSchema}
              taxTypes={comprehensiveTaxTypes(
                application?.tax_registration_types
              )}
            />
          )}
          {!state && (
            <EmptyWrapper>
              The details of your selected government accounts will appear here.
            </EmptyWrapper>
          )}
          <StyledFooterContainer>
            <NewRegistrationIntakeFooter
              {...{
                values,
                isSubmitting,
                error,
                isDisabled,
                onClick,
                progress,
                title: 'State filing',
                submitText: isSubmitPage ? 'Submit' : 'Continue',
                logo
              }}
            />
            <InfoTooltip />
          </StyledFooterContainer>
        </Col>
      </Row>
    </StateSelectorPageWrapper>
  )
}

SelectStateApplication.pageName = 'StateFiling'
SelectStateApplication.title = () => 'Select government accounts'
SelectStateApplication.description = () =>
  "Select the accounts we'll register on your behalf."

export default SelectStateApplication
