import React from 'react'
import { isString } from 'lodash'
import { useFormikContext } from 'formik'
import { SelectField, TextField } from '@middesk/components'
import {
  InfoRequestValues,
  OutboundAttachment,
  DescriptionNote
} from '../../pages/InfoRequestForm'
import {
  InfoRequestItem,
  LlcStructureType,
  OrganizationEntityType
} from '../../types'
import {
  ENTITY_TYPE_IR_OPTIONS,
  LLC_STRUCTURES,
  STATES,
  ADDRESS_TYPES,
  ADDRESS_EXCEPTION_ITEM_TYPE,
  TAXED_AS_ENTITY_TYPE_LABELS
} from '../../lib/constants'
import BooleanField from '../System/BooleanField'
import Address from '../Address'

const TEXT_FIELDS = [
  'ein',
  'contact_name',
  'contact_email',
  'contact_title',
  'industry',
  'dba_name',
  'description_of_operations',
  'purpose',
  'legal_name',
  'futa_year',
  'naics_code',
  'fiscal_year_end',
  'previous_legal_name',
  'sos_business_name'
]

const STATE_FIELDS = ['formation_state']

const SELECT_FIELDS = ['entity_type', 'llc_structure', 'taxed_as_entity_type']

const PHONE_FIELDS = ['contact_phone_number']

const DATE_FIELDS = ['acquisition_date', 'formation_date']

const BOOLEAN_FIELDS = ['acquired', 'non_profit', 'legal_entity_changed']

export type CompanyDetailsInfoRequestItemProps = {
  item: InfoRequestItem
  index: number
}

type DropdownTypes = OrganizationEntityType & LlcStructureType

const OWNER_FIELDS_TO_DROPDOWN_OPTIONS: {
  [index: string]: Readonly<Record<DropdownTypes, string>>
} = {
  entity_type: ENTITY_TYPE_IR_OPTIONS,
  llc_structure: LLC_STRUCTURES,
  taxed_as_entity_type: TAXED_AS_ENTITY_TYPE_LABELS
}

/**
 * Takes a fieldValue for selection, an ownerField, and an index
 * and returns a list of static dropdown options for a SelectField
 */
const buildDynamicDropdownOptions = (
  values: InfoRequestValues,
  ownerField: string,
  index: number
): JSX.Element[] => {
  if (!(ownerField in OWNER_FIELDS_TO_DROPDOWN_OPTIONS)) {
    return []
  }

  const ownerFieldDropdownConstants =
    OWNER_FIELDS_TO_DROPDOWN_OPTIONS[ownerField]

  return Object.keys(ownerFieldDropdownConstants).map(k => {
    return (
      <option
        key={k}
        value={k}
        label={ownerFieldDropdownConstants[k as DropdownTypes]}
        selected={values.items[index].value === k}
      />
    )
  })
}

export const CompanyDetailsInfoRequest = ({
  item,
  index
}: CompanyDetailsInfoRequestItemProps): JSX.Element => {
  const { values } = useFormikContext<InfoRequestValues>()
  let field = <></>
  const questionValue = values.items[index].value

  if (item?.exception_item_type == ADDRESS_EXCEPTION_ITEM_TYPE) {
    field = (
      <Address
        name={`items[${index}].value`}
        label={item.title}
        addressType={ADDRESS_TYPES.primary}
        showErrorMessage={true}
      />
    )
  } else if (item?.owner_field) {
    const ownerField = item.owner_field
    const itemName = `items[${index}].value`
    if (TEXT_FIELDS.includes(ownerField)) {
      field = (
        <TextField
          name={itemName}
          label={item.title}
          placeholder={item.placeholder}
        />
      )
    } else if (BOOLEAN_FIELDS.includes(ownerField)) {
      field = (
        <BooleanField
          name={itemName}
          label={item.title}
          value={isString(questionValue) ? questionValue : null}
        />
      )
    } else if (STATE_FIELDS.includes(ownerField)) {
      field = (
        <>
          <SelectField name={itemName} label={item.title}>
            {STATES.map(({ name: label, abbr: value }) => (
              <option
                key={value}
                value={value}
                label={label}
                selected={values.items[index].value === value}
              />
            ))}
          </SelectField>
        </>
      )
    } else if (DATE_FIELDS.includes(ownerField)) {
      field = (
        <TextField
          name={itemName}
          label={item.title}
          placeholder='MM/DD/YYYY'
          type='date'
        />
      )
    } else if (SELECT_FIELDS.includes(ownerField)) {
      field = (
        <SelectField name={itemName} label={item.title}>
          {buildDynamicDropdownOptions(values, ownerField, index)}
        </SelectField>
      )
    } else if (PHONE_FIELDS.includes(item.owner_field)) {
      field = (
        <TextField
          name={itemName}
          label={item.title}
          placeholder={item.placeholder}
          type='phone'
        />
      )
    }
  }

  return (
    <div key={item.id}>
      {field}
      <DescriptionNote description={item.description} />
      <OutboundAttachment attachment={item.outbound_attachment} />
    </div>
  )
}
