import React, { useContext } from 'react'
import { SelectField, theme } from '@middesk/components'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { groupBy, uniq } from 'lodash'

import { StateDataContext } from '../../contexts/StateDataProvider'
import { ApplicationContext } from '../../contexts/ApplicationProvider'
import { StyledSelectWrapper } from '../CompanyDetails'
import { AvailableAccountStateMap, FormValues } from '../../types'
import {
  BOTH_SUI_AND_SWH_TYPE,
  STATE_UNEMPLOYMENT_INSURANCE_TYPE,
  STATE_TAX_WITHHOLDINGS_TYPE,
  STATE_PAYROLL_ACCOUNT
} from '../../lib/constants'

const { spacing } = theme

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.large};
`

type StateJurisdictionSelection = {
  label: string
  key: string
}

const KEY_LOOKUP: {
  [key: string]: string
} = {
  state_unemployment_insurance: 'Unemployment Insurance',
  state_tax_withholdings: 'Withholding Tax'
}

const StateAccountDropdown = ({
  state,
  availableStatesMap,
  lockedState
}: {
  state: string
  availableStatesMap: AvailableAccountStateMap
  lockedState: boolean
}) => {
  const { values } = useFormikContext<FormValues>()
  const { stateAgencyData } = useContext(StateDataContext)
  const { localJurisdictionsInState } = useContext(ApplicationContext)
  const {
    name: stateName,
    tax_registration: { requires_sui_and_swh_bundled: bundleRequired, agencies }
  } = stateAgencyData.find(({ abbr }) => abbr === state)
  const stateAgenciesByTaxType = groupBy(agencies, 'tax_type')

  if (!availableStatesMap[state]) {
    return <></>
  }

  const keyToLabel = (jurisdictionKey: string) => {
    const localJurisdictions = localJurisdictionsInState || []
    const foundLocal = localJurisdictions.find(
      local => local.slug == jurisdictionKey
    )

    if (foundLocal) {
      return `${foundLocal.type} · ${foundLocal.label}`
    }

    switch (jurisdictionKey) {
      case BOTH_SUI_AND_SWH_TYPE:
        return `${KEY_LOOKUP['state_tax_withholdings']} + ${KEY_LOOKUP['state_unemployment_insurance']}`
      case STATE_UNEMPLOYMENT_INSURANCE_TYPE:
      case STATE_TAX_WITHHOLDINGS_TYPE:
        return `${KEY_LOOKUP[jurisdictionKey]} · ${uniq(
          stateAgenciesByTaxType[jurisdictionKey].map(agency => agency.name)
        ).join(' & ')}`
      default:
        return jurisdictionKey
    }
  }

  const stateJurisdictions: StateJurisdictionSelection[] = []

  // State Agencies
  if (availableStatesMap[state].includes(STATE_PAYROLL_ACCOUNT)) {
    if (bundleRequired) {
      stateJurisdictions.push({
        key: BOTH_SUI_AND_SWH_TYPE,
        label: keyToLabel(BOTH_SUI_AND_SWH_TYPE)
      })
    } else {
      Object.keys(stateAgenciesByTaxType).forEach(tax_type => {
        stateJurisdictions.push({
          key: tax_type,
          label: keyToLabel(tax_type)
        })
      })
    }
  }

  // Local Jurisdictions
  const localJurisdictions = localJurisdictionsInState || []
  localJurisdictions.forEach(localJurisdiction => {
    const { slug } = localJurisdiction

    if (availableStatesMap[state].includes(slug)) {
      stateJurisdictions.push({
        key: slug,
        label: keyToLabel(slug)
      })
    }
  })

  return (
    <Wrapper>
      <StyledSelectWrapper>
        <SelectField
          placeholder='Add one or more accounts'
          name='jurisdictions'
          isClearable
          isMulti
          label={`${stateName} accounts`}
          disabled={!!lockedState}
          value={
            values.jurisdictions
              ? values.jurisdictions.map(jurisdiction => {
                  return {
                    label: keyToLabel(jurisdiction),
                    value: jurisdiction
                  }
                })
              : []
          }
        >
          {stateJurisdictions.map(({ key, label }) => {
            return (
              <option
                key={key}
                value={key}
                label={label}
                selected={(values.jurisdictions || []).includes(key)}
              />
            )
          })}
        </SelectField>
      </StyledSelectWrapper>
    </Wrapper>
  )
}

export default StateAccountDropdown
