import React from 'react'
import * as yup from 'yup'
import EditingBusiness, { agentBusinessSchema } from './EditingBusiness'
import EditingIndividual, { individualSchema } from './EditingIndividual'
import { useFormikContext } from 'formik'
import { FormValues } from '../types'
import { AGENT_BUSINESS, AGENT_INDIVIDUAL } from '../lib/constants'
import { theme } from '@middesk/components'
import RadioField from '../components/System/RadioField'
import styled from 'styled-components'
import { newBusiness, newIndividual } from './Leadership'

const { colors, typography, spacing } = theme

const LabelSpan = styled.span`
  color: ${colors.graphite};
  font-size: ${typography.sizes.medium};
  font-weight: ${typography.weights.bold};
`

export const editingOwnerSchema = yup.object().shape({
  editing_owner_type: yup.string(),
  editing_owner: yup
    .object()
    .when('editing_owner_type', {
      is: editing_owner_type => editing_owner_type === AGENT_INDIVIDUAL,
      then: individualSchema.shape({
        authorized: yup.boolean().when('signature', {
          is: (signature: string) => signature && signature.length > 0,
          then: yup
            .boolean()
            .oneOf([true], 'Signature Authorization required')
            .required()
        })
      })
    })
    .when('editing_owner_type', {
      is: editing_owner_type => editing_owner_type === AGENT_BUSINESS,
      then: agentBusinessSchema
    })
})

const EditingOwner = ({
  editingIndex,
  setEditingIndex,
  preFillSignature,
  setPreFillSignature,
  onCancel,
  logo,
  progress
}: {
  editingIndex: number
  setEditingIndex: React.Dispatch<React.SetStateAction<number | null>>
  preFillSignature: string
  setPreFillSignature: React.Dispatch<React.SetStateAction<string>>
  onCancel: any
  logo?: string
  progress?: string
}): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<FormValues>()

  const isIndividual =
    !values.editing_owner?.object ||
    values.editing_owner?.object === AGENT_INDIVIDUAL

  const onAddOwner = (e: React.MouseEvent) => {
    e.preventDefault()
    setEditingIndex(null)
    setFieldValue(`owners[${editingIndex}]`, values.editing_owner)
    setFieldValue('editing_owner', null)
    setFieldValue('editing_owner_type', null)

    if (values.editing_owner?.object === AGENT_INDIVIDUAL) {
      setPreFillSignature('')
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: spacing.xsmall,
          marginBottom: spacing.large
        }}
      >
        <div>
          <LabelSpan>Owner type</LabelSpan>
        </div>
        <RadioField
          label='Individual'
          name={'editing_owner.object'}
          value={AGENT_INDIVIDUAL}
          checked={values.editing_owner?.object === AGENT_INDIVIDUAL}
          onChange={() => {
            if (values.editing_owner?.object === AGENT_BUSINESS) {
              setFieldValue('editing_owner', newIndividual())
              setFieldValue('editing_owner_type', AGENT_INDIVIDUAL)
            }
          }}
        />
        <RadioField
          label='Business'
          name={'editing_owner.object'}
          value={AGENT_BUSINESS}
          checked={values.editing_owner?.object === AGENT_BUSINESS}
          onChange={() => {
            if (values.editing_owner?.object === AGENT_INDIVIDUAL) {
              setFieldValue('editing_owner', newBusiness())
              setFieldValue('editing_owner_type', AGENT_BUSINESS)
            }
          }}
        />
      </div>

      {isIndividual ? (
        <EditingIndividual
          onAddIndividual={onAddOwner}
          preFillSignature={preFillSignature}
          setPreFillSignature={setPreFillSignature}
          onCancel={onCancel}
          logo={logo}
          progress={progress}
        />
      ) : (
        <EditingBusiness
          onAddBussiness={onAddOwner}
          onCancel={onCancel}
          logo={logo}
          progress={progress}
        />
      )}
    </>
  )
}

export default EditingOwner
