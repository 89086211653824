import { Button as MiddeskButton } from '@middesk/components'
import React from 'react'
import styled from 'styled-components'
import { TYPOGRAPHY_WEIGHTS } from './System/Typography'
import ThemeDecorator from './System/ThemeDecorator'

const InnerButton = styled(({ className, children, ...props }) => {
  return (
    <MiddeskButton className={className} {...props}>
      {children}
    </MiddeskButton>
  )
})`
  ${({
    type = 'primary',
    customTheme: {
      primaryButtonBgColor,
      primaryButtonBgColorHover,
      primaryButtonBorderColor
    }
  }) => {
    let styles = ''

    if (['primary', 'submit'].includes(type)) {
      styles += `
        font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
      `

      if (primaryButtonBgColor) {
        styles += `
          background-color: ${primaryButtonBgColor} !important;
          border-color: ${primaryButtonBorderColor} !important;

          &:hover, &:focus {
            background-color: ${primaryButtonBgColorHover} !important;
          }
        `
      }
    }

    return styles
  }}
`

const Button = ThemeDecorator(InnerButton)

export default Button
