import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import Body from '../Body'
import api from '../../lib/api'
import { AgentApplication } from '../../types'
import SystemSidebar from '../System/Sidebar'
import Preferences from './Preferences'
import Payment from './Payment'
import Complete from './Complete'
import { COLORS } from '../System/Colors'
import SPACING from '../System/Spacing'
import { AccountContext } from '../../contexts/AccountProvider'

const PageBackground = createGlobalStyle`
  html, body {
    background-color: ${COLORS.frost_l1};
    height: 100%;
  }
`

const StyledBody = styled(Body)`
  padding-top: ${SPACING.xxlarge};
`

const StyledContent = styled.div`
  background-color: ${COLORS.white};
  padding: 40px;
`

const PREFERENCES = 'preferences'
const PAYMENT = 'payment'
const COMPLETE = 'complete'

const SOSRegistrationSelection = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const { existingPaymentMethod } = useContext(AccountContext)
  const [fetchingApplication, setFetchingApplication] = useState(true)
  const [application, setApplication] = useState<AgentApplication>({ id })
  const [sos_registration_selection, setSosRegistrationSelection] = useState('')
  const [account_creation_fee_cents, setAccountCreationFeeCents] = useState(0)
  const [state_fee_cents, setStateFeeCents] = useState(0)
  const [annual_fee_cents, setAnnualFeeCents] = useState(0)
  const [state, setState] = useState('')
  const [selectionStep, setSelectionStep] = useState(PREFERENCES)
  const [error, setError] = useState('')

  const pageHeader: { [key: string]: string } = {
    [PREFERENCES]: 'Select your preference',
    [PAYMENT]: 'Payment information',
    [COMPLETE]: "You're all set!"
  }

  const updateSOSRegistrationSelection = (sosRegistrationSelection: string) => {
    const goToComplete =
      sosRegistrationSelection === 'self_register' || existingPaymentMethod
    setSelectionStep(goToComplete ? COMPLETE : PAYMENT)
  }

  const showError = () => {
    setError('Unable to save preferences - please refresh and try again.')
    setSosRegistrationSelection('')
    setSelectionStep(PREFERENCES)
  }

  useEffect(() => {
    const loadApplication = async () => {
      let applicationJson

      try {
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        ;[applicationJson] = await Promise.all([
          api.get(`/v1/agent/applications/${application.id}`)
        ])
      } catch (e: any) {
        if (e.status === 404) {
          window.location.href = '/'
        }
      }

      const fetchedState = applicationJson.saved_tax_registrations.reduce(
        (a: Array<string>, e: any) =>
          a.includes(e.state) ? a : a.concat(e.state),
        []
      )[0]

      if (applicationJson.sos_registration_selection == 'middesk') {
        window.location.href = '/'
      }

      setApplication(applicationJson)
      setSosRegistrationSelection(applicationJson.sos_registration_selection)
      setState(fetchedState)
      setAccountCreationFeeCents(applicationJson.sos_account_creation_fee_cents)
      setStateFeeCents(applicationJson.sos_estimated_state_fee_cents)
      setAnnualFeeCents(applicationJson.sos_annual_fee_cents)
      setFetchingApplication(false)
    }

    loadApplication()
  }, [])

  return (
    <>
      <PageBackground />
      <SystemSidebar hideMenu={true} fetchingApplication={fetchingApplication}>
        <></>
      </SystemSidebar>
      <StyledBody
        title={pageHeader[selectionStep]}
        contentsLoading={fetchingApplication}
      >
        <StyledContent>
          {selectionStep === PREFERENCES && (
            <Preferences
              sos_registration_selection={sos_registration_selection}
              setSosRegistrationSelection={setSosRegistrationSelection}
              submitAction={updateSOSRegistrationSelection}
              state={state}
              account_creation_fee_cents={account_creation_fee_cents}
              state_fee_cents={state_fee_cents}
              annual_fee_cents={annual_fee_cents}
              error={error}
            />
          )}
          {selectionStep === PAYMENT && (
            <Payment
              account_creation_fee_cents={account_creation_fee_cents}
              state_fee_cents={state_fee_cents}
              annual_fee_cents={annual_fee_cents}
              submitAction={() => setSelectionStep(COMPLETE)}
            />
          )}
          {selectionStep === COMPLETE && (
            <Complete
              sos_registration_selection={sos_registration_selection}
              applicationId={application.id}
              errorAction={showError}
            />
          )}
        </StyledContent>
      </StyledBody>
    </>
  )
}

export default SOSRegistrationSelection
