import React, { useEffect } from 'react'
import styled from 'styled-components'
import { theme } from '@middesk/components'
import RadioField from '../System/RadioField'
import {
  BOTH_SUI_AND_SWH_TYPE,
  MANAGED_BY_MIDDESK,
  STATE_TAX_WITHHOLDINGS_TYPE,
  STATE_UNEMPLOYMENT_INSURANCE_TYPE,
  UNMANAGED_BY_MIDDESK
} from '../../lib/constants'
import AgenciesDescription from './AgenciesDescription'
import UnmanagedItemsForm from './UnmanagedItemsForm'
import { useFormikContext } from 'formik'
import { taxTypeIntentKey } from '../../lib/helpers'

const { spacing, typography, colors } = theme

const StyledContainer = styled.div`
  padding: ${spacing.xlarge};
  border-radius: ${typography.sizes.medium};
  border: 1px solid ${colors.frost};
  margin-bottom: ${spacing.xlarge};
  font-size: ${typography.sizes.medium};

  .label {
    display: inline-block;
    color: ${colors.graphite};
    font-weight: ${typography.weights.bold};
  }
`

const StyledRadioWrapper = styled.div`
  margin-top: 16px;
`

const TAX_TYPE_COPY: {
  [key: string]: string
} = {
  [STATE_TAX_WITHHOLDINGS_TYPE]: 'withholdings tax',
  [STATE_UNEMPLOYMENT_INSURANCE_TYPE]: 'unemployment insurance',
  [BOTH_SUI_AND_SWH_TYPE]: 'withholdings tax and unemployment insurance'
}

const RegistrationOptions = ({
  taxType,
  agencies,
  priceInCents
}: {
  taxType: string
  agencies: any
  priceInCents?: number
}): JSX.Element => {
  const {
    setFieldValue,
    values: { tax_registrations }
  } = useFormikContext<{
    tax_registrations: [any]
  }>()
  const formKey = taxTypeIntentKey(taxType)

  const selection = tax_registrations[0] && tax_registrations[0][formKey]

  const updateCustomerIntent = (newIntent: string) => {
    setFieldValue(`tax_registrations.0.${formKey}`, newIntent)
  }

  useEffect(() => {
    if (selection) return

    updateCustomerIntent(MANAGED_BY_MIDDESK)
  }, [selection, agencies])

  return (
    <StyledContainer>
      <RadioField
        label={
          <div className='label'>
            Register for {TAX_TYPE_COPY[taxType]}{' '}
            {priceInCents && priceInCents > 0
              ? `($${priceInCents / 100})`
              : null}
          </div>
        }
        checked={selection === MANAGED_BY_MIDDESK}
        name={`tax_registrations[0].${formKey}`}
        onChange={() => updateCustomerIntent(MANAGED_BY_MIDDESK)}
        value={MANAGED_BY_MIDDESK}
        style={{ marginBottom: spacing.small }}
      />
      {selection === MANAGED_BY_MIDDESK && (
        <AgenciesDescription agencies={agencies} />
      )}
      <StyledRadioWrapper>
        <RadioField
          label={
            <div className='label'>
              I&apos;ve already registered for {TAX_TYPE_COPY[taxType]}
            </div>
          }
          checked={selection === UNMANAGED_BY_MIDDESK}
          name={`tax_registrations[0].${formKey}`}
          onChange={() => updateCustomerIntent(UNMANAGED_BY_MIDDESK)}
          value={UNMANAGED_BY_MIDDESK}
          style={{ marginBottom: spacing.small }}
        />
        {selection === UNMANAGED_BY_MIDDESK && (
          <UnmanagedItemsForm agencies={agencies} />
        )}
      </StyledRadioWrapper>
    </StyledContainer>
  )
}

export default RegistrationOptions
