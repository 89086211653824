import React from 'react'
import styled from 'styled-components'

type PartnerParam = { name: string; slug: string }

const PAYCHEX_SLUG = 'paychex'

export const SIGNUP_FLOW = 'SIGNUP_FLOW'
export const EXISTING_USER_FLOW = 'EXISTING_USER_FLOW'

const FLOW_PREFIXES: { [key: string]: string } = {
  [SIGNUP_FLOW]: 'By creating an account',
  [EXISTING_USER_FLOW]: 'By checking this box'
}

const StyledPartnerReferralFormLabel = styled.div`
  display: block;
`

const DefaultMessage = ({
  flow,
  partnerName
}: {
  flow: string
  partnerName: string
}) => (
  <StyledPartnerReferralFormLabel>
    {FLOW_PREFIXES[flow]} you agree to our{' '}
    <a href='https://agent.middesk.com/terms' target='_blank' rel='noreferrer'>
      Terms
    </a>{' '}
    and{' '}
    <a href='https://www.middesk.com/privacy' target='_blank' rel='noreferrer'>
      Privacy Policy
    </a>
    . You also agree that Middesk can share with {partnerName} any data you
    provide or any data generated by Middesk in connection with the Services.{' '}
    {partnerName}&apos;s use of the data will be governed by {partnerName}
    &apos;s terms of service and privacy policy.
  </StyledPartnerReferralFormLabel>
)

const PaychexMessage = ({ flow }: { flow: string }): JSX.Element => (
  <StyledPartnerReferralFormLabel>
    {FLOW_PREFIXES[flow]} you agree to our{' '}
    <a href='https://agent.middesk.com/terms' target='_blank' rel='noreferrer'>
      Terms
    </a>{' '}
    and{' '}
    <a href='https://www.middesk.com/privacy' target='_blank' rel='noreferrer'>
      Privacy Policy
    </a>
    . You also agree that Middesk can share with Paychex any data you provide or
    any data generated by Middesk in connection with the Services.{' '}
    Paychex&apos;s use of the data will be governed by Paychex&apos;s terms of
    service and privacy policy.
  </StyledPartnerReferralFormLabel>
)

const ConsentLabel = ({
  partner,
  flow
}: {
  partner: PartnerParam
  flow: string
}) => {
  if (partner.slug.toLowerCase() === PAYCHEX_SLUG) {
    return <PaychexMessage flow={flow} />
  }

  return <DefaultMessage flow={flow} partnerName={partner.name} />
}

export const SignupConsentLabel = ({ partner }: { partner: PartnerParam }) => (
  <ConsentLabel flow={SIGNUP_FLOW} partner={partner} />
)

export const ExistingAccountConsentLabel = ({
  partner
}: {
  partner: PartnerParam
}) => <ConsentLabel flow={EXISTING_USER_FLOW} partner={partner} />
