import React from 'react'
import styled, { css } from 'styled-components'
import SPACING from '../../../System/Spacing'
import { Body } from '../../../System/Typography'
import Link from '../../../System/Link'
import { DepartmentElement } from '../AccountInformation'

const Styles = css`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.small};
`

const DepartmentOfTaxation = styled(
  ({ className }): JSX.Element => {
    return (
      <div {...{ className }}>
        <Body>
          1. Navigate to{' '}
          <Link href='https://hitax.hawaii.gov/_/' target='_blank'>
            https://hitax.hawaii.gov/_/
          </Link>
        </Body>
        <Body>2. Login using your credentials</Body>
        <Body>
          3. On the main menu, select <b>More...</b>
        </Body>
        <Body>
          4. Scroll down to <b>Registrations</b> and select{' '}
          <b>Manage Mailing Addresses</b>
        </Body>
        <Body>
          5. Select <b>Update Mailing Address</b> next to{' '}
          <b>Default Mailing Address</b>
        </Body>
        <Body>
          6. Update the address to{' '}
          <b>9450 SW Gemini Dr PMB 73938, Beaverton, OR, 97008</b>
        </Body>
        <Body>
          7. Select <b>Next</b>
        </Body>
        <Body>8. Review the address ensuring PMB 73838 is still showing</Body>
        <Body>
          9. Select <b>Submit</b>
        </Body>
        <br />
      </div>
    )
  }
)`
  ${Styles}
`

const DepartmentOfEmploymentSecurity = styled(({ className }) => {
  return (
    <div {...{ className }}>
      <Body>
        1. Navigate to{' '}
        <Link href='https://huiclaims.hawaii.gov/#/'>
          https://huiclaims.hawaii.gov/#/
        </Link>{' '}
        and select Employer Services
      </Body>
      <Body>2. Login using your DOL credentials </Body>
      <Body>3. Scroll down to Forms</Body>
      <Body>
        4. Select form <b>UC-25</b> and press <b>Continue</b>
      </Body>
      <Body>
        5. Select <b>Update Mailing Address</b> next to{' '}
        <b>Default Mailing Address</b>
      </Body>
      <Body>
        6. Scroll down to Mailing Address and input{' '}
        <b>9450 SW Gemini Dr PMB 73938, Beaverton, OR, 97008</b>
      </Body>
      <Body>
        7. Select <b>Save and Continue</b>
      </Body>
      <Body>8. Review the address ensuring PMB 73838 is still showing</Body>
      <Body>
        9. Select <b>Submit UC-25</b>
      </Body>
    </div>
  )
})`
  ${Styles}
`

const Hawaii = (): Array<DepartmentElement> => [
  {
    name: '',
    children: [],
    renderMailingAddressCallout: true
  },
  {
    name: 'Department of Taxation',
    link: 'https://hitax.hawaii.gov/_/',
    children: <DepartmentOfTaxation />
  },
  {
    name: 'Department of Employment Security',
    link: 'https://huiclaims.hawaii.gov/#/',
    children: <DepartmentOfEmploymentSecurity />,
    renderCompleteLater: true
  }
]

export default Hawaii
