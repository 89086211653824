import React, { useContext, useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'

import { SelectField, TextField, theme } from '@middesk/components'

import Address from '../components/Address'
import NewRegistrationIntakeFooter from '../components/NewRegistrationIntakeFooter'
import { Page, PageProps, Error } from '../components/Page'
import { ADDRESS_TYPES, PO_BOX_PATTERN } from '../lib/constants'
import { FormValues } from '../types'
import { ApplicationContext } from '../contexts/ApplicationProvider'
import { StyledSelectWrapper } from './CompanyDetails'
import { mapStateAbbreviationToName } from '../components/StateFilter'

const { spacing } = theme

const SelectWrapper = styled(StyledSelectWrapper)`
  margin: ${spacing.small} 0 ${spacing.small} 0;
`

const StyledTextField = styled(TextField)`
  margin-bottom: ${spacing.small};
`

const validationSchema = yup.object().shape({
  primary_address: yup.object().shape({
    address_line1: yup
      .string()
      .required('Address line 1 is required')
      .test(
        'po_box',
        'Should not be a PO Box',
        (v: any) => !PO_BOX_PATTERN.test(v)
      ),
    address_line2: yup.string().optional().nullable(),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    postal_code: yup.string().required('Postal code is required'),
    type: yup.string().optional()
  })
})

const AddressDetails: Page = ({
  onNext,
  onCancel,
  updateValidationSchema,
  isSubmitting,
  error,
  logo,
  progress
}: PageProps) => {
  const { values, setFieldValue } = useFormikContext<FormValues>()

  const [loading, setLoading] = useState(true)
  const { state } = useContext(ApplicationContext)
  const stateName = mapStateAbbreviationToName(state)
  const registeringInPrimaryState = values.primary_address?.state === state

  useEffect(() => {
    updateValidationSchema && updateValidationSchema(validationSchema)
    setLoading(false)
  }, [])

  const onStateLocationAnswerChange = (e: { value: string }) => {
    if (String(e.value) === 'false') {
      setFieldValue('state_locations_count', undefined)
      setFieldValue(`secondary_addresses[${state}]`, [])
    }
  }

  const handleUpdateSecondaryAddresses = () => {
    let newCount = values.state_locations_count

    if (values.state_locations_count < 0) {
      setFieldValue('state_locations_count', 0)
      newCount = 0
    } else if (values.state_locations_count > 25) {
      setFieldValue('state_locations_count', 25)
      newCount = 25
    }

    const currentAddresses = values.secondary_addresses[state] || []
    const currentCount = currentAddresses.length

    if (newCount === currentCount) {
      return
    } else if (newCount > currentCount) {
      const newAddresses = new Array(newCount - currentCount)
      setFieldValue(
        `secondary_addresses[${state}]`,
        currentAddresses.concat(newAddresses.fill({ state }))
      )
    } else {
      setFieldValue(
        `secondary_addresses[${state}]`,
        currentAddresses.slice(0, newCount - currentCount)
      )
    }
  }

  const stateLocationLabel = registeringInPrimaryState
    ? `Do you have additional business locations or offices in ${stateName}?`
    : `Do you operate business locations or have offices in ${stateName}?`

  return (
    <>
      <Address
        name='primary_address'
        label='Primary Business Address'
        addressType={ADDRESS_TYPES.primary}
        allowPOBox={false}
      />
      <SelectWrapper>
        <SelectField
          name='has_state_locations'
          label={stateLocationLabel}
          onChange={onStateLocationAnswerChange}
        >
          <option
            key='true'
            value='true'
            label='Yes'
            selected={String(values.has_state_locations) === 'true'}
          />
          <option
            key='false'
            value='false'
            label='No'
            selected={String(values.has_state_locations) === 'false'}
          />
        </SelectField>
      </SelectWrapper>
      {String(values.has_state_locations) === 'true' && (
        <>
          <StyledTextField
            label={`How many${
              registeringInPrimaryState ? ' additional' : ''
            } business locations do you have in ${stateName}?`}
            placeholder={`Enter number of${
              registeringInPrimaryState ? ' additional' : ''
            } business locations in ${stateName}`}
            name='state_locations_count'
            onBlur={handleUpdateSecondaryAddresses}
            min={0}
            max={25}
            type='number'
          />
          {(values.secondary_addresses[state] || []).map((address, index) => {
            return (
              <Address
                key={`secondary-address-${index}`}
                name={`secondary_addresses[${state}][${index}]`}
                label={`${stateName} Business Address ${index + 1}`}
                addressType={ADDRESS_TYPES.secondary}
              />
            )
          })}
        </>
      )}
      <Error validationSchema={validationSchema} />
      <NewRegistrationIntakeFooter
        {...{
          values,
          onNext,
          onCancel,
          isSubmitting,
          error,
          isDisabled: loading || !validationSchema.isValidSync(values),
          onClick: () => onNext(values),
          progress,
          title: 'Business information',
          logo
        }}
      />
    </>
  )
}

AddressDetails.pageName = 'AddressDetails'
AddressDetails.title = () => 'Address details'
AddressDetails.description = () =>
  'Please review the information below and edit if required.'

export default AddressDetails
