import { TextField } from '@middesk/components'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import Address from '../components/Address'
import { FormValues } from '../types'
import NewRegistrationIntakeFooter from '../components/NewRegistrationIntakeFooter'
import { GapWrapper } from '../components/Page'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { ErrorContainer, FieldError } from './EditingIndividual'

const GenericBusinessErrorMessage = () => (
  <ErrorContainer>Please review validation errors</ErrorContainer>
)

export const agentBusinessSchema = yup.object().shape({
  legal_name: yup.string().required('Legal name required').nullable(),
  dba_name: yup.string().optional().nullable(),
  address: yup.object({
    address_line1: yup.string().required('Address required'),
    address_line2: yup.string().optional().nullable(),
    city: yup.string().required('City required'),
    state: yup.string().required('State required'),
    postal_code: yup.string().required('ZIP Code required')
  }),
  ein: yup
    .string()
    .test(
      'len',
      'EIN must be 9 characters',
      value => !!value && value.replace(/\D+/g, '').length === 9
    )
    .required('EIN required')
    .nullable(),
  contact_email: yup
    .string()
    .email('Email must be valid')
    .required('Contact email required')
    .nullable(),
  ownership_start_date: yup
    .mixed()
    .test('isValid', 'Ownership start date is invalid', function (value) {
      const { path, createError, parent } = this

      if (value) {
        if (!moment(value).isValid()) {
          return createError({
            path,
            message: 'Ownership start date is required'
          })
        }

        if (parent.formation_date) {
          if (new Date(value) < new Date(parent.formation_date)) {
            return createError({
              path,
              message: `Ownership start date must be on or after the business formation date: ${moment(
                parent.formation_date
              ).format('MM/DD/YYYY')}`
            })
          }
        }

        if (moment(value).isAfter(moment())) {
          return createError({
            path,
            message: 'Ownership start date must be before today'
          })
        }
      } else {
        return createError({
          path,
          message: 'Ownership start date is required'
        })
      }

      return true
    }),
  ownership_percentage: yup
    .number()
    .min(0, 'Ownership percentage must be greater than or equal to 0')
    .max(100, 'Ownership percentage must be less than or equal to 100')
    .required('Ownership percentage is required')
    .nullable()
})

const EditingBusiness = ({
  onAddBussiness,
  onCancel,
  logo,
  progress
}: {
  onAddBussiness: (e: React.MouseEvent) => void
  onCancel: any
  logo?: string
  progress?: string
}): JSX.Element => {
  const { values, setFieldValue, errors } = useFormikContext<FormValues>()

  const [showFullValidation, setShowFullValidation] = useState<boolean>(false)

  useEffect(() => {
    // For Validation
    setFieldValue(`editing_owner.formation_date`, values.formation_date)
  }, [values.formation_date])

  const validateThenSave = (e: any) => {
    e.preventDefault()
    setShowFullValidation(true)

    if (isEmpty(errors)) {
      onAddBussiness(e)
    }
  }

  return (
    <>
      <GapWrapper>
        <div>
          <TextField
            autoFocus
            name={`editing_owner.legal_name`}
            label='Legal name of business'
            placeholder='Enter business legal name'
          />
          <FieldError
            fieldName='editing_owner.legal_name'
            showFullValidation={showFullValidation}
          />
        </div>
        <div>
          <TextField
            name={`editing_owner.dba_name`}
            label='Doing business as (DBA) name'
            placeholder='Enter business dba name'
            optional
          />
          <FieldError
            fieldName='editing_owner.dba_name'
            showFullValidation={showFullValidation}
          />
        </div>
        <div>
          <TextField
            name={`editing_owner.contact_email`}
            label='Contact email'
            placeholder='Enter business contact email'
          />
          <FieldError
            fieldName='editing_owner.contact_email'
            showFullValidation={showFullValidation}
          />
        </div>
        <div>
          <TextField
            type='ein'
            name={`editing_owner.ein`}
            label='Federal employer identification number (FEIN)'
            placeholder='XX-XXXXXXX'
          />
          <FieldError
            fieldName='editing_owner.ein'
            showFullValidation={showFullValidation}
          />
        </div>
        <div>
          <TextField
            label='Ownership start date'
            placeholder='MM/DD/YYYY'
            type='date'
            name={`editing_owner.ownership_start_date`}
          />
          <FieldError
            fieldName='editing_owner.ownership_start_date'
            showFullValidation={showFullValidation}
          />
        </div>
        <div>
          <TextField
            label='Ownership Percentage'
            placeholder='Enter a percentage'
            type='number'
            min='0'
            max='100'
            name={`editing_owner.ownership_percentage`}
          />
          <FieldError
            fieldName='editing_owner.ownership_percentage'
            showFullValidation={showFullValidation}
          />
        </div>
        <Address
          name={`editing_owner.address`}
          label='Business address'
          showErrorMessage
        />
      </GapWrapper>
      {showFullValidation && !isEmpty(errors) && (
        <GenericBusinessErrorMessage />
      )}
      <NewRegistrationIntakeFooter
        {...{
          values,
          onCancel: onCancel,
          isSubmitting: false,
          isDisabled: showFullValidation && !isEmpty(errors),
          onClick: validateThenSave,
          progress,
          title: 'Ownership information',
          submitText: 'Add business',
          logo
        }}
      />
    </>
  )
}

export default EditingBusiness
