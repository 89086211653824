import React, { useEffect } from 'react'
import { TextField } from '@middesk/components'
import { useFormikContext } from 'formik'
import * as yup from 'yup'
import NewRegistrationIntakeFooter from '../components/NewRegistrationIntakeFooter'
import { Error, Page, PageProps, GapWrapper } from '../components/Page'
import { FormValues } from '../types'

const validationSchema = yup.object().shape({
  contact_name: yup
    .string()
    .required('Please provide a contact name')
    .nullable(),
  contact_email: yup
    .string()
    .email('Please provide a valid email')
    .required('Please provide a contact email')
    .nullable(),
  contact_phone_number: yup
    .string()
    .required('Please provide a contact phone number')
    .nullable(),
  contact_title: yup
    .string()
    .required('Please provide a contact title')
    .nullable()
})

const ApplicantDetails: Page = ({
  onNext,
  onCancel,
  updateValidationSchema,
  isSubmitting,
  pageNumber,
  error,
  logo,
  progress
}: PageProps) => {
  const { values } = useFormikContext<FormValues>()

  useEffect(() => {
    updateValidationSchema && updateValidationSchema(validationSchema)
  }, [])

  return (
    <>
      <GapWrapper>
        <TextField
          placeholder='Enter your name'
          name='contact_name'
          label='Your Name'
        />
        <TextField
          name='contact_email'
          label='Contact Email'
          placeholder='Enter your email'
        />
        <TextField
          name='contact_phone_number'
          label='Contact Phone Number'
          placeholder='Enter your phone number'
          type='phone'
        />
        <TextField
          name='contact_title'
          label='Contact Title'
          placeholder='Enter your title'
        />
      </GapWrapper>

      <Error validationSchema={validationSchema} />
      <NewRegistrationIntakeFooter
        {...{
          values,
          onNext,
          isSubmitting,
          error,
          isDisabled: !validationSchema.isValidSync(values),
          onClick: () => onNext(values),
          progress,
          title: 'Contact information',
          logo,
          onCancel: pageNumber > 0 ? onCancel : undefined
        }}
      />
    </>
  )
}

ApplicantDetails.validationSchema = validationSchema

ApplicantDetails.pageName = 'ApplicantDetails'
ApplicantDetails.title = () => 'Contact information'
ApplicantDetails.description = () =>
  'We will reach out to you if we require any additional follow-up.'

export default ApplicantDetails
