import React from 'react'
import styled, { css } from 'styled-components'
import SPACING from '../../../System/Spacing'
import { Body } from '../../../System/Typography'
import Link from '../../../System/Link'
import { DepartmentElement } from '../AccountInformation'

const Styles = css`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.small};
`

const FormDepartmentOfRevenue = styled(
  ({ className, children }): JSX.Element => {
    return <div {...{ className }}>{children}</div>
  }
)`
  ${Styles}
`

const DepartmentOfRevenue = styled(({ className }) => {
  return (
    <div {...{ className }}>
      <Body>
        1. Navigate to{' '}
        <Link href='https://www.uctax.pa.gov' target='_blank'>
          https://www.uctax.pa.gov
        </Link>
      </Body>
      <Body>
        2. Log in using your credentials for the Unemployment Compensation
        Management System
      </Body>
      <Body>
        3. Select <b>Employer Profile</b> and select <b>Addresses</b>
      </Body>
      <Body>
        4. Update your current mailing address to{' '}
        <b>9450 SW Gemini Dr PMB 73938, Beaverton, OR, 97008</b>
      </Body>
      <Body>5. Verify this address and ensure PMB 73938 is still showing</Body>
      <Body>
        6. Select <b>Submit</b>
      </Body>
    </div>
  )
})`
  ${Styles}
`

const Pennsylvania = (
  departments?: Array<DepartmentElement>
): Array<DepartmentElement> => [
  {
    name: 'Department of Revenue',
    link: 'https://www.revenue.pa.gov/Pages/default.aspx',
    children: (
      <FormDepartmentOfRevenue>
        {
          (departments || []).find(
            ({ name }) => name === 'Department of Revenue'
          )?.children
        }
      </FormDepartmentOfRevenue>
    )
  },
  {
    name: '',
    children: [],
    renderMailingAddressCallout: true
  },
  {
    name: 'Department of Revenue',
    link: 'https://www.revenue.pa.gov/Pages/default.aspx',
    children: <DepartmentOfRevenue />,
    renderCompleteLater: true
  }
]

export default Pennsylvania
