import React, { useState, useEffect, useContext } from 'react'
import { useFormikContext } from 'formik'
import styled from 'styled-components'
import * as yup from 'yup'
import moment from 'moment'
import { TextField, theme } from '@middesk/components'

import { ApplicationContext } from '../../contexts/ApplicationProvider'
import { Page, GapWrapper, PageProps } from '../../components/Page'
import Address from '../../components/Address'
import NewRegistrationIntakeFooter from '../../components/NewRegistrationIntakeFooter'
import { FormValues, LocalQuestion } from '../../types'
import DynamicLocalQuestions from './DynamicLocalQuestions'

import {
  SQL_UNSIGNED_FOUR_BYTE_INTEGER_MAX,
  generateQuestionSchema
} from '../DynamicStatePage'

const { colors, typography } = theme

const Notice = styled.div`
  font-size: ${typography.sizes.medium};
  color: ${colors.karlLight1};
  margin-top: -10px;
`

const localPageValidationSchema = (
  state: string,
  jurisdictionSlug: string,
  localQuestions: LocalQuestion[]
) => {
  const filteredQuestions = localQuestions.filter((question: LocalQuestion) => {
    return !question.api_only
  })

  const questionsSchema = generateQuestionSchema(
    state,
    filteredQuestions.map(question => {
      return { ...question, categories: [] }
    })
  )

  return yup.object().shape({
    local_details: yup.object().shape({
      [jurisdictionSlug]: yup.object().shape({
        employee_count: yup
          .number()
          .required('Employee Count Required')
          .min(1, 'Must be more than 0')
          .max(SQL_UNSIGNED_FOUR_BYTE_INTEGER_MAX)
          .nullable(),
        employee_name: yup
          .string()
          .required('Employee Name Required')
          .nullable(),
        payroll_date: yup
          .string()
          .required('Payroll Date Required')
          .nullable()
          .test(
            'valid date',
            'This must be a valid date',
            value => !value || moment(value).isValid()
          ),
        start_date_in_jurisdiction: yup
          .string()
          .required('Start Date in Jurisdiction Required')
          .nullable()
          .test(
            'valid date',
            'This must be a valid date',
            value => !value || moment(value).isValid()
          ),
        estimated_monthly_payroll: yup
          .number()
          .required('Estimated Monthly Payroll Required')
          .min(1, 'Must be more than 0')
          .max(SQL_UNSIGNED_FOUR_BYTE_INTEGER_MAX)
          .nullable(),
        employee_address: yup.object().shape({
          address_line1: yup.string().required('Required'),
          address_line2: yup.string().optional().nullable(),
          city: yup.string().required('Required'),
          state: yup
            .string()
            .test(
              'in state',
              `Employee address must be in ${state}`,
              value => !value || value === state
            )
            .required('Required'),
          postal_code: yup.string().required('Required')
        })
      })
    }),
    questions: yup.object().shape({
      [jurisdictionSlug]: yup.object().shape(questionsSchema)
    })
  })
}

const LocalTaxPage: Page = ({
  onNext,
  onCancel,
  updateValidationSchema,
  isSubmitting,
  error,
  logo,
  progress,
  pageNumber,
  firstLocalPageAt,
  localJurisdictionRegistrations
}: PageProps) => {
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const { fetchingLocalQuestions, localQuestionsMap, state } = useContext(
    ApplicationContext
  )
  const [schema, setSchema] = useState<any>(yup.object())

  const localPage = pageNumber - (firstLocalPageAt || 0)
  const emptyLocal = { jurisdictionSlug: '', jurisdictionLabel: '' }
  const localRegistration = localJurisdictionRegistrations
    ? localJurisdictionRegistrations[localPage]
    : emptyLocal
  const { jurisdictionSlug, jurisdictionLabel } = localRegistration

  useEffect(() => {
    const validationSchema = localPageValidationSchema(
      state,
      jurisdictionSlug,
      localQuestionsMap[jurisdictionSlug] || []
    )
    updateValidationSchema && updateValidationSchema(validationSchema)
    setSchema(validationSchema)
  }, [jurisdictionSlug, localQuestionsMap[jurisdictionSlug]])

  useEffect(() => {
    setFieldValue(`local_details.${jurisdictionSlug}.state`, state)
    setFieldValue(
      `local_details.${jurisdictionSlug}.jurisdiction_slug`,
      jurisdictionSlug
    )
  }, [jurisdictionSlug])

  if (
    fetchingLocalQuestions ||
    !firstLocalPageAt ||
    !localJurisdictionRegistrations
  ) {
    return <></>
  }

  const localDetailKey = `local_details.${jurisdictionSlug}`
  const employeeCount =
    values.local_details[jurisdictionSlug]?.employee_count || 0

  return (
    <>
      <GapWrapper>
        <TextField
          label={`Number of employees in ${jurisdictionLabel}`}
          placeholder='Enter number'
          name={`${localDetailKey}.employee_count`}
          type='number'
          showErrorMessage
        />
        {employeeCount > 1 && (
          <Notice>
            Please provide information on your first employee in{' '}
            {jurisdictionLabel} below:
          </Notice>
        )}
        <TextField
          label='Employee name'
          placeholder='Enter employee name'
          name={`${localDetailKey}.employee_name`}
          showErrorMessage
        />
        <TextField
          label='Date of first payroll'
          placeholder='MM/DD/YYYY'
          type='date'
          name={`${localDetailKey}.payroll_date`}
          showErrorMessage
        />
        <TextField
          label='Start date in jurisdiction'
          placeholder='MM/DD/YYYY'
          type='date'
          name={`${localDetailKey}.start_date_in_jurisdiction`}
          showErrorMessage
        />
        <TextField
          label={`Estimated monthly payroll${
            employeeCount > 1 ? ' for all employees' : ''
          }`}
          type='number'
          placeholder='Enter number'
          name={`${localDetailKey}.estimated_monthly_payroll`}
          showErrorMessage
        />
        <Address
          name={`${localDetailKey}.employee_address`}
          label='Employee address'
          showErrorMessage
        />

        <DynamicLocalQuestions
          localQuestions={localQuestionsMap[jurisdictionSlug] || []}
          jurisdictionSlug={jurisdictionSlug}
        />
      </GapWrapper>

      <NewRegistrationIntakeFooter
        {...{
          values,
          onNext,
          onCancel,
          isSubmitting,
          error,
          isDisabled: !schema.isValidSync(values),
          onClick: () => {
            onNext(values)
          },
          progress,
          title: `${jurisdictionLabel} information`,
          logo
        }}
      />
    </>
  )
}

LocalTaxPage.pageName = 'LocalTaxPage'
LocalTaxPage.title = (_app, localJurisdictionLabel) =>
  `${localJurisdictionLabel} information`
LocalTaxPage.description = (_app, localJurisdictionLabel) =>
  `Provide information about your employees in ${localJurisdictionLabel} below.`

export default LocalTaxPage
