import React, { FormEvent, useEffect } from 'react'
import { AgentApplication, FormValues } from '../../../types'
import {
  STATE_MAP,
  STATE_TAX_WITHHOLDINGS_TYPE,
  STATE_UNEMPLOYMENT_INSURANCE_TYPE
} from '../../../lib/constants'
import { TextField, theme } from '@middesk/components'
import styled from 'styled-components'
import { Body } from '../../System/Typography'
import { useFormikContext } from 'formik'
import NewRegistrationIntakeFooter from '../../NewRegistrationIntakeFooter'
import { PageProps } from '../../Page'
import * as yup from 'yup'
import { get } from 'lodash'
import moment from 'moment'

const { colors } = theme

const centsToDollarField = (cents: number | null | undefined): string => {
  if (!cents) return ''

  return (cents / 100).toFixed(2)
}

const dollarFieldToCents = (dollars: number | string): number | undefined => {
  if (dollars === '') return

  return (dollars as number) * 100
}

const PayrollReportsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  &:nth-child(0) {
    padding-left: 0;
    background-color: red;
  }

  .quarter {
    padding: 1rem;
    padding: 0.5rem;

    &:nth-child(1),
    &:nth-child(4n + 1) {
      padding-left: 0;
    }

    &:nth-child(4),
    &:nth-child(4n) {
      padding-right: 0;
    }

    &:nth-last-child(n + 5) {
      border-bottom: 2px solid ${colors.dawn};
    }
  }
`

export const showPayrollReports = ({
  state,
  managedTaxTypes
}: {
  state: string
  managedTaxTypes: string[]
}): boolean => {
  if (['ID', 'WV', 'IA'].includes(state)) return true

  const managedSui = managedTaxTypes.includes(STATE_UNEMPLOYMENT_INSURANCE_TYPE)
  const managedSwh = managedTaxTypes.includes(STATE_TAX_WITHHOLDINGS_TYPE)
  if (managedSui && ['TN', 'AL', 'WY'].includes(state)) return true
  if (managedSwh && 'MO' === state) return true

  return false
}

const schema = yup.object().shape({
  payroll_reports: yup.array().of(
    yup.object().shape({
      valueInDollars: yup
        .number()
        .required('Required')
        .min(0, "Amount can't be less than 0")
        .nullable()
    })
  )
})

const getAllQuarters = (
  payroll_date?: string
): { quarter: number; year: number }[] => {
  const start = moment(payroll_date)
  const end =
    moment(payroll_date) < moment()
      ? moment().add(4, 'Q')
      : moment(payroll_date).add(5, 'Q')

  const quarters = [{ quarter: start.quarter(), year: start.year() }]

  while (start.add(1, 'Q').isBefore(end)) {
    quarters.push({ quarter: start.quarter(), year: start.year() })
  }

  return quarters
}

const PayrollReport = ({
  onNext,
  application,
  onCancel,
  isSubmitting,
  error,
  progress,
  logo,
  updateValidationSchema
}: PageProps): JSX.Element => {
  const { values, setFieldValue, isValid } = useFormikContext<FormValues>()

  const payrollQuestion = application?.questions?.find(({ key }) =>
    key.includes('_payroll_date')
  )

  useEffect(() => {
    const formattedPayrollReports = getAllQuarters(payrollQuestion?.value).map(
      ({ quarter, year }) => {
        const futureQuarter = moment(String(year)).quarter(quarter) > moment()
        const field = futureQuarter ? 'estimated_amount' : 'amount'
        const existingPayrollReport = (application?.payroll_reports || []).find(
          report => report.quarter === quarter && report.year === year
        )

        const { amount, estimated_amount } = existingPayrollReport || {}
        const valueInCents = get(existingPayrollReport, field)
        return {
          quarter,
          year,
          amount,
          estimated_amount,
          field,
          valueInDollars: valueInCents
            ? centsToDollarField(valueInCents)
            : undefined
        }
      }
    )

    setFieldValue('payroll_reports', formattedPayrollReports)
  }, [payrollQuestion?.value])

  useEffect(() => {
    updateValidationSchema(schema)
  }, [])

  const payrollReportsValue = values?.payroll_reports || []
  const firstPayrollReport = payrollReportsValue[0]
  const lastPayrollReport = payrollReportsValue[payrollReportsValue.length - 1]
  return (
    <>
      <Body style={{ marginBottom: '15px' }}>
        <b>Gross quarterly wages</b>
      </Body>
      <PayrollReportsContainer>
        {firstPayrollReport &&
          Array.from(Array(firstPayrollReport.quarter - 1)).map((_x, i) => (
            <div className='quarter' key={`pre-${i}`}>
              <TextField
                label={`Q${firstPayrollReport.quarter - i - 1} ${
                  firstPayrollReport.year
                }`}
                name={`pre-${i}`}
                placeholder='N/A'
                disabled
              />
            </div>
          ))}
        {payrollReportsValue.map(({ quarter, year }, i) => (
          <div className='quarter' key={`${year}-${quarter}`}>
            <TextField
              type='number'
              name={`payroll_reports[${i}].valueInDollars`}
              label={`Q${quarter} ${year}`}
              step='1'
              placeholder={
                get(values, `payroll_reports[${i}].field`) ===
                'estimated_amount'
                  ? 'Enter est. amount'
                  : 'Enter amount'
              }
              onChange={({
                currentTarget: { value }
              }: FormEvent<HTMLInputElement>) => {
                const updatedReport = {
                  ...payrollReportsValue[i],
                  valueInDollars: value
                }
                const valueInCents = dollarFieldToCents(value)
                if (updatedReport.field === 'amount') {
                  updatedReport.amount = valueInCents
                } else {
                  updatedReport.estimated_amount = valueInCents
                }
                const updatedReports = [...payrollReportsValue]
                updatedReports[i] = updatedReport
                setFieldValue('payroll_reports', updatedReports)
              }}
              showErrorMessage
            />
          </div>
        ))}
        {firstPayrollReport &&
          Array.from(Array(4 - lastPayrollReport.quarter)).map((_x, i) => (
            <div className='quarter' key={`post-${i}`}>
              <TextField
                label={`Q${lastPayrollReport.quarter + i + 1} ${
                  lastPayrollReport.year
                }`}
                name={`post-${i}`}
                placeholder='N/A'
                disabled
              />
            </div>
          ))}
      </PayrollReportsContainer>
      <NewRegistrationIntakeFooter
        {...{
          values,
          onNext,
          onCancel,
          isSubmitting,
          error,
          isDisabled: !isValid,
          onClick: () => {
            onNext(values)
          },
          progress,
          title: 'State information',
          logo
        }}
      />
    </>
  )
}

PayrollReport.pageName = 'PayrollReport'
PayrollReport.title = ({ saved_tax_registrations }: AgentApplication) => {
  const abbr = saved_tax_registrations && saved_tax_registrations[0]?.state
  if (!abbr) return ''

  const stateName = STATE_MAP[abbr]?.name
  return `${stateName} state information`
}
PayrollReport.description = ({ saved_tax_registrations }: AgentApplication) => {
  const abbr = saved_tax_registrations && saved_tax_registrations[0]?.state
  if (!abbr) return ''

  const stateName = STATE_MAP[abbr]?.name
  return `Provide information about your current or expected ${stateName} operations below.`
}

export default PayrollReport
