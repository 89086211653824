import React from 'react'
import styled, { css } from 'styled-components'
import SPACING from '../../../System/Spacing'
import { Body } from '../../../System/Typography'
import Link from '../../../System/Link'
import { DepartmentElement } from '../AccountInformation'

const Styles = css`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.small};
`

const DivisionEmploymentSecurity = styled(
  ({ className, children }): JSX.Element => {
    return <div {...{ className }}>{children}</div>
  }
)`
  ${Styles}
`

const FormStep = styled(Body)`
  padding-left: 20px;
`

const DepartmentOfRevenue = styled(({ className }) => {
  const formLink =
    'https://www.ncdor.gov/taxes-forms/sales-and-use-tax/sales-and-use-tax-forms-and-certificates/miscellaneous-forms/nc-ac-business-address-correction'

  return (
    <div {...{ className }}>
      <Body>
        In order to facilitate your withholding account transfer with North
        Carolina&apos;s Department of Revenue, we need you to complete the
        enclosed online form. The North Carolina Department of Revenue requires
        that this form be done online and signed by an Officer/Owner through
        their portal.
      </Body>
      <Body>
        Here is the link to the form (make sure to click{' '}
        <b>NC-AC Online Form</b> on the following page):{' '}
        <Link href={formLink} target='_blank'>
          {formLink}
        </Link>
      </Body>
      <Body>
        The address for the mailing address portion on the right-hand side
        should be <b>9450 SW Gemini Dr PMB 73938, Beaverton, OR, 97008</b>. The
        left-hand side should be the physical address of your business.
      </Body>
      <Body>
        <b>Instructions for completing the form:</b>
      </Body>
      <FormStep>
        <b>Taxpayer Type:</b> Select FEIN
      </FormStep>
      <FormStep>
        <b>Taxpayer ID:</b> Enter your EIN
      </FormStep>
      <FormStep>
        <b>Business Legal Name:</b> Enter the full legal name of your company
        <br />- Make sure to include any punctuation and entity designations
        (i.e. Inc., LLC, Corporation, etc.)
      </FormStep>
      <FormStep>
        <b>Tax Type:</b> Select Withholding
      </FormStep>
      <FormStep>
        <b>Effective Date of Change:</b> Enter today&apos;s date
      </FormStep>
      <FormStep>
        <b>Address Line 1:</b> Enter the first line of the physical address
        currently on file with the state
      </FormStep>
      <FormStep>
        <b>Address Line 2:</b> Enter the second line of the physical address
        currently on file with the state
      </FormStep>
      <FormStep>
        <b>City:</b> Enter the city of the physical address currently on file
        with the state
      </FormStep>
      <FormStep>
        <b>State:</b> Enter the state of the physical address currently on file
        with the state
      </FormStep>
      <FormStep>
        <b>Zip Code:</b> Enter the zip code of the physical address currently on
        file with the state
      </FormStep>
      <FormStep>
        <b>Mailing address same as physical address:</b> Leave this unchecked
      </FormStep>
      <FormStep>
        <b>Mailing Address Line 1:</b> Enter <em>9450 SW Gemini Dr</em>
      </FormStep>
      <FormStep>
        <b>Mailing Address Line 2:</b> Enter <em>PMB 73938</em>
      </FormStep>
      <FormStep>
        <b>Mailing Address City:</b> Enter <em>Beaverton</em>
      </FormStep>
      <FormStep>
        <b>Mailing Address State:</b> Select <em>OR</em>
      </FormStep>
      <FormStep>
        <b>Mailing Address Zip Code:</b> Enter <em>97008</em>
      </FormStep>
      <FormStep>
        <b>Email Address:</b> Enter{' '}
        <em>[businesslegalname]@agent.middesk.com</em>
        <br />- e.g. if your business is called Amy&apos;s Donuts, you would
        type <em>amysdonuts@agent.middesk.com</em>
        <br />- Feel free to include or exclude any entity designations (i.e.
        Inc., LLC, Corporation, etc.) here
      </FormStep>
      <FormStep>
        <b>Confirm Email Address:</b> Re-enter{' '}
        <em>[businesslegalname]@agent.middesk.com</em>
      </FormStep>
      <FormStep>
        <b>Phone Number:</b> Enter your preferred contact number
      </FormStep>
      <FormStep>
        <b>Signature:</b> Have your signatory draw their signature here
      </FormStep>
      <FormStep>
        <b>Name:</b> Enter the name of your signatory
      </FormStep>
      <FormStep>
        <b>Title:</b> Enter the title of your signatory
      </FormStep>
      <br />
    </div>
  )
})`
  ${Styles}
`

const NorthCarolina = (
  departments?: Array<DepartmentElement>
): Array<DepartmentElement> => [
  {
    name: 'Division of Employment Security',
    link: 'https://fed.des.nc.gov/ofis/citizen/pages/public/Login.aspx',
    children: (
      <DivisionEmploymentSecurity>
        {
          (departments || []).find(
            ({ name }) => name === 'Division of Employment Security'
          )?.children
        }
      </DivisionEmploymentSecurity>
    )
  },
  {
    name: '',
    children: [],
    renderMailingAddressCallout: true
  },
  {
    link: 'https://idpprod.nc.gov:8443/nidp/app/login',
    name: 'Department of Revenue',
    children: <DepartmentOfRevenue />,
    renderCompleteLater: true
  }
]

export default NorthCarolina
