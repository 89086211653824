import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import * as yup from 'yup'
import { useFormikContext } from 'formik'

import {
  Attribute,
  TextField,
  Icons,
  CheckBox,
  SelectField
} from '@middesk/components'

import {
  ADDRESS_TYPES,
  ENTITY_TYPES,
  ENTITY_TYPE_LABELS,
  PO_BOX_PATTERN,
  PO_BOX_TEST,
  PO_BOX_TEST_MESSAGE,
  STATES
} from '../../../lib/constants'
import { formatAddress } from '../../../lib/helpers'
import {
  Company,
  OrganizationEntityType,
  TransfersIntakeComponentProps,
  TransfersIntakeFormValues
} from '../../../types'
import Address from '../../Address'
import AttributeColumn from '../../AttributeColumn'
import { COLORS } from '../../System/Colors'
import SPACING from '../../System/Spacing'
import TransferIntakeCard from '../TransfersIntakeCard'
import CheckboxLabel from '../../CheckboxLabel'
import useApplication from '../../../lib/useApplication'
import APICompany from '../../../lib/api/company'

const { Edit } = Icons

const validationSchema = yup.object().shape({
  legal_name: yup.string().required('Required'),
  sos_business_name: yup.string().required('Required'),
  ein: yup
    .string()
    .test(
      'len',
      'EIN must be 9 characters',
      value => !!value && value.replace(/\D+/g, '').length === 9
    )
    .required('Please enter EIN')
    .nullable(),
  entity_type: yup.string().required('Please select entity type').nullable(),
  formation_state: yup
    .string()
    .required('Please enter state of formation')
    .nullable(),
  formation_date: yup
    .date()
    .max(Date(), 'Formation date must be in the past')
    .required('Please enter date of formation')
    .nullable(),
  primary_address: yup.object().shape({
    address_line1: yup
      .string()
      .required('Required')
      .test(
        PO_BOX_TEST,
        PO_BOX_TEST_MESSAGE,
        (v: any) => !PO_BOX_PATTERN.test(v)
      ),
    address_line2: yup.string().optional().nullable(),
    city: yup.string().required('Required'),
    state: yup.string().required('Required'),
    postal_code: yup.string().required('Required'),
    type: yup.string().optional()
  }),
  mailing_address: yup
    .object({
      address_line1: yup.string().required('Address line is required.'),
      address_line2: yup.string().optional().nullable(),
      city: yup.string().required('Required'),
      state: yup.string().required('Required'),
      postal_code: yup.string().required('Required'),
      type: yup.string().optional()
    })
    .when('$checked', (checked: boolean, schema: yup.ObjectSchema) => {
      if (checked) {
        return schema.optional().nullable().default(null)
      }

      return schema
    })
})

const MAILING_ADDRESS_KEY = 'mailing_address'
const SAME_ADDRESS_CHECKED_KEY = 'same_address_checked'
const SOS_BUSINESS_NAME_KEY = 'sos_business_name'
const SAME_NAME_CHECKED_KEY = 'same_name_checked'

const StyledSection = styled.div`
  background: ${COLORS.frost_l1};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${SPACING.medium};
  padding: ${SPACING.large} ${SPACING.large} ${SPACING.small} ${SPACING.large};
`

const StyledAttribute = styled(Attribute)`
  > div {
    margin-bottom: ${SPACING.xsmall};
  }
`

const StyledEdit = styled(Edit)`
  cursor: pointer;
`

const StyledCheckBoxContainer = styled.div`
  padding-bottom: ${SPACING.medium};
`

const BusinessInformation = ({
  onNext,
  onBack,
  setValidationSchema
}: TransfersIntakeComponentProps): JSX.Element => {
  setValidationSchema && setValidationSchema(validationSchema)
  const {
    values,
    validateForm,
    setFieldValue
  } = useFormikContext<TransfersIntakeFormValues>()
  const shouldDisableContinue = !validationSchema.isValidSync(values)

  const [hasPrefilledData, setHasPrefilledData] = useState(
    !!values.primary_address?.address_line1 &&
      !!values.legal_name &&
      !!values.ein &&
      !!values.sos_business_name &&
      !!values.entity_type &&
      !!values.formation_state &&
      !!values.formation_date
  )
  const [previousMailingValues, setPreviousMailingValues] = useState(
    values.mailing_address
  )
  const [previousSOSName, setPreviousSOSName] = useState(
    values.sos_business_name
  )
  const { id: applicationId } = useParams<{ id: string }>()
  const { application } = useApplication(applicationId)
  const [company, setCompany] = useState<Company | undefined>()

  useEffect(() => {
    if (!values.ein && !!company?.ein) {
      setFieldValue('ein', company.ein)
    }
  }, [company])

  useEffect(() => {
    validateForm()
  }, [values])

  useEffect(() => {
    if (application) {
      APICompany.show(application.company_id).then(setCompany)
    }
  }, [application])

  const onEdit = () => {
    setHasPrefilledData(false)
  }

  const primaryAddress = values.primary_address
    ? formatAddress(values.primary_address, '\n')
    : undefined

  const onSetSameAddressChecked = (toggle: boolean) => {
    if (toggle) {
      const tempMailingValues = values.mailing_address
      setFieldValue(MAILING_ADDRESS_KEY, {
        ...values.primary_address,
        type: ADDRESS_TYPES.mailing
      })

      setPreviousMailingValues(tempMailingValues)
    } else {
      setFieldValue(MAILING_ADDRESS_KEY, previousMailingValues)
    }

    setFieldValue(SAME_ADDRESS_CHECKED_KEY, toggle)
  }

  const onSetSameNameChecked = (toggle: boolean) => {
    if (toggle) {
      const tempSOSName = values.sos_business_name
      setFieldValue(SOS_BUSINESS_NAME_KEY, values.legal_name)

      setPreviousSOSName(tempSOSName)
    } else {
      setFieldValue(SOS_BUSINESS_NAME_KEY, previousSOSName)
    }

    setFieldValue(SAME_NAME_CHECKED_KEY, toggle)
  }

  const einField = company?.ein ? (
    <StyledAttribute
      label='Employer Identification Number'
      sublabel='If you need to change this value, please email agent@middesk.com'
    >
      {values.ein}
    </StyledAttribute>
  ) : (
    <TextField
      label='Employer Identification Number'
      sublabel='This is your business’s federal Employer Identification
            Number(EIN), assigned by the IRS. It should be a 9-digit number of
            the format XX-XXXXXXX'
      placeholder='XX-XXXXXXX'
      name='ein'
      type='ein'
    />
  )

  return (
    <TransferIntakeCard {...{ onNext, onBack, shouldDisableContinue }}>
      {hasPrefilledData ? (
        <StyledSection>
          <AttributeColumn>
            <StyledAttribute label='Legal Business Name'>
              {values.legal_name}
            </StyledAttribute>
            <StyledAttribute label='Business name registered with Secretary of State'>
              {values.sos_business_name}
            </StyledAttribute>
            <StyledAttribute label='Employer Identification Number'>
              {values.ein}
            </StyledAttribute>
            <StyledAttribute label='Entity Type'>
              {values.entity_type.split('_').join(' ')}
            </StyledAttribute>
            <StyledAttribute label='Formation Date'>
              {values.formation_date}
            </StyledAttribute>
            <StyledAttribute label='Formation State'>
              {values.formation_state}
            </StyledAttribute>
            <StyledAttribute label='Primary Business Address'>
              {primaryAddress}
            </StyledAttribute>
          </AttributeColumn>
          <div>
            <StyledEdit stroke={COLORS.karl} onClick={onEdit} />
          </div>
        </StyledSection>
      ) : (
        <>
          <TextField
            name='legal_name'
            label='Legal business name'
            placeholder='Legal business name'
          />
          <StyledCheckBoxContainer>
            <CheckBox
              label={
                <CheckboxLabel>
                  Business Name registered with Secretary of State is the same
                  as Legal Business Name
                </CheckboxLabel>
              }
              onClick={() => onSetSameNameChecked(!values.same_name_checked)}
              checked={values.same_name_checked}
            />
          </StyledCheckBoxContainer>
          {values.same_name_checked ? (
            <></>
          ) : (
            <TextField
              name='sos_business_name'
              label='Business name registered with Secretary of State'
              placeholder='Enter business name registered with Secretary of State'
            />
          )}

          {einField}
          <SelectField name='entity_type' label='Entity type'>
            {Object.keys(ENTITY_TYPES).map(k => (
              <option
                key={k}
                value={ENTITY_TYPES[k as OrganizationEntityType]}
                label={ENTITY_TYPE_LABELS[k as OrganizationEntityType]}
                selected={
                  values.entity_type ===
                  ENTITY_TYPES[k as OrganizationEntityType]
                }
              />
            ))}
          </SelectField>
          <SelectField
            name='formation_state'
            label='State of formation'
            sublabel='This is the state your company was legally formed in and may be different than the one wherein it is headquartered'
          >
            {STATES.map(({ name: label, abbr: value }) => (
              <option
                key={value}
                value={value}
                label={label}
                selected={values.formation_state === value}
              />
            ))}
          </SelectField>
          <TextField
            label='Formation Date'
            placeholder='MM/DD/YYYY'
            type='date'
            name='formation_date'
          />
          <Address
            name='primary_address'
            label='Primary address'
            addressType={ADDRESS_TYPES.primary}
          />
        </>
      )}
      <StyledCheckBoxContainer>
        <CheckBox
          label={
            <CheckboxLabel>
              Mailing Address is the same as Primary Address
            </CheckboxLabel>
          }
          onClick={() => onSetSameAddressChecked(!values.same_address_checked)}
          checked={values.same_address_checked}
        />
      </StyledCheckBoxContainer>
      {values.same_address_checked ? (
        <></>
      ) : (
        <Address
          name={MAILING_ADDRESS_KEY}
          label='Current mailing address'
          addressType={ADDRESS_TYPES.mailing}
        />
      )}
    </TransferIntakeCard>
  )
}

export default BusinessInformation
