import React, { ReactNode, useContext } from 'react'

import styled from 'styled-components'
import { AuthContext } from '../../../contexts/AuthProvider'

import { ContainerProps } from '../../../types'
import Link from '../Link'
import { COLORS } from '../Colors'

import Middesk from '../Icons/Middesk'

import SPACING from '../Spacing'
import { Large, TYPOGRAPHY_SIZES } from '../Typography'
import { bannerPadStyle } from '../SandboxBanner'
import API from '../../../lib/api'
import { MAX_WIDTH_MOBILE } from '../../Body'

export const MIN_SIDEBAR_WIDTH = '150px'
export const MAX_SIDEBAR_WIDTH = '400px'

export interface SidebarProps extends ContainerProps {
  children?: ReactNode | Array<ReactNode>
  logo?: string
  fetchingApplication?: boolean
  hideMenu?: boolean
  backgroundColor?: string
}

const PartnerLogo = styled.img`
  margin-left: ${SPACING.xlarge};
  max-height: 60px;
  max-width: 150px;
`

const Sidebar = styled(
  ({
    className,
    children,
    logo,
    fetchingApplication,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    backgroundColor
  }: SidebarProps): JSX.Element => {
    const { inGuestMode, impersonator } = useContext(AuthContext)

    const sidebarLogo = () => {
      if (fetchingApplication) {
        return null
      }

      if (logo) {
        return (
          <div>
            <PartnerLogo src={logo} />
          </div>
        )
      }

      return (
        <Link to='/' disabled={inGuestMode}>
          <Middesk />
        </Link>
      )
    }

    return (
      <div
        style={bannerPadStyle(API.sandboxMode(), !!impersonator)}
        {...{ className }}
      >
        {sidebarLogo()}
        <div>{children}</div>
      </div>
    )
  }
)`
  @media (max-width: ${MAX_WIDTH_MOBILE}) {
    display: none;
  }

  ${props =>
    `background: ${
      props.backgroundColor ? props.backgroundColor : COLORS.frost_l1
    };`}
  height: 100%;
  max-width: ${MAX_SIDEBAR_WIDTH};
  position: fixed;
  padding: ${SPACING.xlarge} 0;
  min-width: fit-content;
  top: 0;
  width: 20%;

  > a:first-child {
    margin: 0px ${SPACING.xlarge};
  }

  > div:nth-child(2) {
    flex-grow: 1;
    margin-top: ${SPACING.xlarge};

    > div {
      margin-top: ${SPACING.medium};
    }
  }

  > div {
    width: 100%;
  }

  > a:first-child {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: ${SPACING.small};

    ${Large} {
      color: ${COLORS.black};
      font-size: ${TYPOGRAPHY_SIZES.xlarge}px;
    }
  }

  z-index: 1;
`

export default Sidebar
