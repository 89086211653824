import React, { useEffect, useState } from 'react'
import { BackButton, Form, theme } from '@middesk/components'
import { set, isEmpty, get, intersection } from 'lodash'
import styled from 'styled-components'
import Body from '../../components/Body'
import Sidebar from '../../components/Sidebar'
import Page from '../../components/System/Layout/Page'
import {
  ADDRESS_TYPES,
  AF_STATES_REQUIRING_STOCK_TABLE,
  AGENT_BUSINESS,
  CUSTOMER_INFORMATION_REQUEST_STATUS
} from '../../lib/constants'
import {
  AnnualFiling,
  AnnualFilingCollection,
  Address as AddressType,
  AgentQuestion,
  ConfirmCompany,
  QuestionsCollection,
  Question,
  StateQuestionMap,
  StockTable as StockTableType,
  Owner
} from '../../types'
import ActionFooter from '../../components/ActionFooter'
import LoaderButton from '../../components/LoaderButton'
import { useHistory, useParams } from 'react-router'
import { useLocation } from 'react-router-dom'
import APIAnnualFiling from '../../lib/api/annualFiling'
import APICompany from '../../lib/api/company'
import APIQuestions from '../../lib/api/questions'
import Loader from '../../components/System/Loader'
import {
  getStockTable,
  isLlcEntityType,
  BASE_64_IDENTIFIER
} from '../../lib/helpers'

import { checkCompanyDependency } from '../DynamicStatePage'
import ContactInformation, {
  contactInformationSchema
} from './ContactInformation'
import BusinessInformation, {
  businessInformationSchema
} from './BusinessInformation'
import Individuals, { individualsSchema } from './Individuals'
import StockTable, { stockTableSchema } from './StockTable'
import StateQuestions from './StateQuestions'
import { editingIndividualSchema } from '../EditingIndividual'
import { statePageValidationSchema } from '../DynamicStatePage'
import MobileTopbar from '../../components/System/MobileTopbar'

const { colors, spacing } = theme

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xxlarge};
`

const StyledLoaderButton = styled(LoaderButton)`
  display: flex;
  justify-content: end;
`

const ErrorMessage = styled.div`
  display: flex;
  color: ${colors.magenta};
  justify-content: flex-end;
`

export const InnerFormColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.large};
`

const filterQuestions = (questions: Question[], values: ConfirmCompany) => {
  return questions.filter(
    (question: Question) =>
      question.type !== 'stock_table' &&
      checkCompanyDependency(question, values)
  )
}

const requireStockTable = (values: ConfirmCompany | undefined) => {
  return (
    !isLlcEntityType(values?.entity_type) &&
    !isEmpty(intersection(AF_STATES_REQUIRING_STOCK_TABLE, values?.states))
  )
}

const getValidationSchema = (
  stateQuestionsList: StateQuestionMap[],
  values: ConfirmCompany | undefined
) => {
  let validationSchema = contactInformationSchema
    .concat(businessInformationSchema.required())
    .concat(individualsSchema.required())

  if (values?.editing_owner) {
    validationSchema = validationSchema.concat(
      editingIndividualSchema.required()
    )
  }

  if (requireStockTable(values)) {
    validationSchema = validationSchema.concat(stockTableSchema.required())
  }

  stateQuestionsList.map(({ state, questions }) => {
    questions = questions as Question[]
    if (values) questions = filterQuestions(questions, values)

    if (!isEmpty(questions)) {
      validationSchema = validationSchema.concat(
        statePageValidationSchema(questions, state).required()
      )
    }
  })

  return validationSchema
}

const getAgentQuestions = (annualFilings: AnnualFiling[]) => {
  const transformedQuestions: {
    [key: string]: { [key: string]: string }[]
  } = {}

  annualFilings.forEach(({ state, questions }: AnnualFiling) => {
    questions.forEach(({ value, key }: AgentQuestion) => {
      set(transformedQuestions, `${state}.${key}`, value)
    })
  })

  return transformedQuestions
}

const getStates = (annualFilings: AnnualFiling[]) =>
  annualFilings.map(annualFiling => annualFiling.state)

const ConfirmInformation = (): JSX.Element => {
  const { push } = useHistory()
  const { pathname } = useLocation()
  const editingInformation = pathname.includes('/edit-information')

  const { companyId } = useParams<{ companyId: string }>()
  const [companyForm, setCompanyForm] = useState<ConfirmCompany>()
  const [annualFilings, setAnnualFilings] = useState<AnnualFiling[]>()
  const [stateQuestionsList, setStateQuestionsList] = useState<
    StateQuestionMap[]
  >([])
  const [fetchingCompany, setFetchingCompany] = useState(true)
  const [fetchingStateQuestionsList, setFetchingStateQuestionsList] = useState(
    true
  )
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [editingIndividuals, setEditingIndividuals] = useState(false)

  const fetching = fetchingCompany || fetchingStateQuestionsList

  const validationSchema = getValidationSchema(stateQuestionsList, companyForm)

  useEffect(() => {
    APIAnnualFiling.index(companyId, {
      status: CUSTOMER_INFORMATION_REQUEST_STATUS,
      information_confirmed: false
    }).then(({ data = [] }: AnnualFilingCollection) => {
      setAnnualFilings(data || [])
    })
  }, [])

  useEffect(() => {
    APICompany.show(companyId)
      .then((e: any) =>
        setCompanyForm({
          ...e,
          stock_table: getStockTable(e?.stock_table),
          primary_address: e.addresses?.find(
            (address: AddressType) => address?.type === ADDRESS_TYPES.primary
          )
        })
      )
      .finally(() => setFetchingCompany(false))
  }, [])

  useEffect(() => {
    if (!annualFilings) return

    if (annualFilings.length === 0) {
      setFetchingStateQuestionsList(false)
      return
    }

    APIQuestions.get({
      categories: ['annual_filing'],
      states: getStates(annualFilings)
    })
      .then(({ data = [] }: QuestionsCollection) => {
        setStateQuestionsList(data)
      })
      /* eslint-disable-next-line no-console */
      .catch(e => console.log(e))
      .finally(() => setFetchingStateQuestionsList(false))
  }, [annualFilings])

  useEffect(() => {
    companyForm &&
      annualFilings &&
      setCompanyForm({
        ...companyForm,
        questions: getAgentQuestions(annualFilings),
        states: getStates(annualFilings)
      })
  }, [!!companyForm, annualFilings])

  const onChange = (e: ConfirmCompany): void => {
    setCompanyForm({
      ...companyForm,
      ...e,
      stock_table: e?.stock_table
    })
  }

  const onSubmit = () => {
    setSubmitting(true)
    setErrorMessage('')

    const addresses = (companyForm?.addresses || []).filter(
      ({ type }) => type !== 'primary'
    )

    const getQuestions = (
      stateQuestionList: StateQuestionMap[],
      values: ConfirmCompany
    ) => {
      const flatQuestions: AgentQuestion[] = []

      Object.keys(companyForm?.questions || {}).forEach((state: string) => {
        const questions = filterQuestions(
          stateQuestionList.find(
            stateQuestions => stateQuestions.state === state
          )?.questions || [],
          values
        )
        questions.forEach((question: Question) => {
          flatQuestions.push({
            state,
            question: question.label,
            key: question.key,
            value: get(values, `questions.${state}.${question.key}`) || ''
          })
        })
      })

      return flatQuestions
    }

    const stockTable: StockTableType = {
      value: {
        authorized_shares: companyForm?.stock_table?.value?.authorized_shares,
        issued_shares: companyForm?.stock_table?.value?.issued_shares
      }
    }

    const successPage = `/companies/${companyId}/${
      editingInformation ? '' : 'updates'
    }`

    APICompany.update({
      ...companyForm,
      set_annual_filings: true,
      set_stock_table: requireStockTable(companyForm),
      addresses: [
        ...addresses,
        {
          ...companyForm?.primary_address,
          type: 'primary'
        }
      ].filter((e): e is AddressType => !!e),
      owners: (companyForm?.owners || []).map((i: Owner) => {
        if (i.object === AGENT_BUSINESS) return i

        return {
          ...i,
          signature: (i.signature || '').replace(BASE_64_IDENTIFIER, '')
        }
      }),
      questions: companyForm && getQuestions(stateQuestionsList, companyForm),
      stock_table: {
        value: stockTable?.value
      }
    })
      .then(() => push(successPage))
      .catch(e => {
        if (e.messages && e.messages.length > 0) {
          setErrorMessage(e.messages[0])
        } else {
          setErrorMessage('Something went wrong. Please try again')
        }
        setSubmitting(false)
      })
  }

  return (
    <Page background={colors.white}>
      <Sidebar />
      <MobileTopbar
        {...{
          fetchingApplication: fetchingStateQuestionsList || fetchingCompany
        }}
      />
      <Loader {...{ loading: fetchingStateQuestionsList || fetchingCompany }}>
        <Body
          {...{
            title: editingInformation
              ? 'Edit information'
              : 'Confirm information',
            description: editingInformation
              ? ''
              : 'Please review the information below and edit if required.'
          }}
        >
          <Form
            {...{
              initialValues: {
                ...(companyForm || {}),
                editing_owner: null
              },
              validationSchema,
              onChange,
              onSubmit,
              enableReinitialize: true
            }}
          >
            <FlexColumn>
              <ContactInformation />
              <BusinessInformation />
              <Individuals setEditingIndividuals={setEditingIndividuals} />
              {requireStockTable(companyForm) && <StockTable />}
              {(stateQuestionsList || []).map(({ state, questions }) => {
                questions = questions as Question[]
                if (companyForm)
                  questions = filterQuestions(questions, companyForm)

                if (isEmpty(questions)) {
                  return <></>
                }
                return (
                  <StateQuestions
                    key={state}
                    questions={questions}
                    state={state}
                  />
                )
              })}
              {!editingIndividuals && (
                <>
                  {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                  <ActionFooter
                    separated={false}
                    secondaryAction={
                      !editingInformation && (
                        <BackButton
                          onClick={() => push('/updates')}
                          type='cancel'
                        />
                      )
                    }
                  >
                    <StyledLoaderButton
                      {...{
                        submitting,
                        isDisabled:
                          fetching ||
                          !validationSchema.isValidSync(companyForm),
                        submitText: editingInformation ? 'Save' : 'Confirm'
                      }}
                    />
                  </ActionFooter>
                </>
              )}
            </FlexColumn>
          </Form>
        </Body>
      </Loader>
    </Page>
  )
}

export default ConfirmInformation
