import React from 'react'
import styled, { css } from 'styled-components'
import SPACING from '../../../System/Spacing'
import { Body } from '../../../System/Typography'
import Link from '../../../System/Link'
import { DepartmentElement } from '../AccountInformation'

const Styles = css`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.small};
`

const RevenueServices = styled(
  ({ className, children }): JSX.Element => {
    return <div {...{ className }}>{children}</div>
  }
)`
  ${Styles}
`

const DepartmentOfLabor = styled(({ className }) => {
  return (
    <div {...{ className }}>
      <Body>
        1. Navigate to{' '}
        <Link
          href='https://reemployme.maine.gov/accessme/faces/login/login.xhtml'
          target='_blank'
        >
          https://reemployme.maine.gov/accessme/faces/login/login.xhtml
        </Link>
      </Body>
      <Body>
        2. Click <b>Employer Login</b>
      </Body>
      <Body>
        3. At the top of the page, select <b>Account Maintenance</b>
      </Body>
      <Body>
        4. In the drop-down menu, select <b>Maintain Address</b>
      </Body>
      <Body>
        5. On this page, select <b>Different</b> for #2 “Unemployment Tax
        Mailing Address is”
      </Body>
      <Body>
        6. In #3, input the address{' '}
        <b>9450 SW Gemini Dr PMB 73938, Beaverton, OR, 97008</b>
      </Body>
      <Body>
        7. On the bottom of the page, select <b>Submit</b>
      </Body>
      <Body>8. Review the address ensuring PMB 73838 is still showing</Body>
      <Body>
        9. Select <b>Submit</b>
      </Body>
    </div>
  )
})`
  ${Styles}
`

const Maine = (
  departments?: Array<DepartmentElement>
): Array<DepartmentElement> => [
  {
    name: 'Revenue Services',
    link: 'https://apps.web.maine.gov/cgi-bin/online/suwtaxreg/index',
    children: (
      <RevenueServices>
        {
          (departments || []).find(({ name }) => name === 'Revenue Services')
            ?.children
        }
      </RevenueServices>
    )
  },
  {
    name: '',
    children: [],
    renderMailingAddressCallout: true
  },
  {
    name: 'Department of Labor',
    link: 'https://reemployme.maine.gov/accessme/faces/login/login.xhtml',
    children: <DepartmentOfLabor />,
    renderCompleteLater: true
  }
]

export default Maine
