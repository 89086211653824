import React from 'react'
import { CreateStateFilingSideBarProps } from '../types'
import IntakeSidebarContent from './IntakeSidebarContent'
import Link from './System/Link'

const title = 'Register in a new state'
const content = (
  <span>
    In order to complete the registration process, we need the following
    information about your business. If you need help on the registration, see{' '}
    <Link target='_blank' href='https://help.middesk.com/hc/en-us'>
      Middesk&apos;s Help Center
    </Link>
    .
  </span>
)

const CreateStateFilingSideBar = ({
  hasStockTablePage = false,
  showPaymentInformation = false,
  requiresSosRegistration = false,
  showPayrollReports = false,
  hasDynamicStatePage = true,
  localJurisdictionRegistrations = [],
  current = 0
}: CreateStateFilingSideBarProps): JSX.Element => {
  const ContactInformationGroup = {
    title: 'Contact information',
    page: 1,
    pageCount: 2
  }

  const BusinessInformationGroup = {
    title: 'Business information',
    page: ContactInformationGroup.page + ContactInformationGroup.pageCount,
    pageCount: 3
  }

  const LeadershipInformationGroup = {
    title: 'Officer / Owner information',
    page: BusinessInformationGroup.page + BusinessInformationGroup.pageCount,
    pageCount: [1, hasStockTablePage].filter(x => x).length
  }

  const StateInformationGroup = {
    title: 'State information',
    page:
      LeadershipInformationGroup.page + LeadershipInformationGroup.pageCount,
    pageCount: [1, showPayrollReports].filter(x => x).length
  }

  const statePageOffset = hasDynamicStatePage
    ? StateInformationGroup.page + StateInformationGroup.pageCount
    : StateInformationGroup.page

  const localGroups = localJurisdictionRegistrations.map(
    (localJurisdiction, index) => {
      return {
        title: `${localJurisdiction.jurisdictionLabel} information`,
        page: statePageOffset + index,
        pageCount: 1
      }
    }
  )

  const ReviewAndSubmitGroup = {
    title: 'Review & submit',
    page: statePageOffset + localGroups.length,
    pageCount: [1, showPaymentInformation].filter(x => x).length
  }

  const SosGroup = {
    title: 'Secretary of State',
    page: ReviewAndSubmitGroup.page + ReviewAndSubmitGroup.pageCount
  }

  const groups = [
    ContactInformationGroup,
    BusinessInformationGroup,
    LeadershipInformationGroup,
    ...(hasDynamicStatePage ? [StateInformationGroup] : []),
    ...localGroups,
    ReviewAndSubmitGroup,
    ...(requiresSosRegistration ? [SosGroup] : [])
  ]

  return (
    <>
      <IntakeSidebarContent
        {...{
          title,
          content,
          groups,
          current
        }}
      />
    </>
  )
}

export default CreateStateFilingSideBar
