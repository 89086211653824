import React, { useEffect, useState } from 'react'
import { Loader } from '@middesk/components'
import { useParams } from 'react-router'

import LandingGlobalStyle from '../LandingGlobalStyle'
import { PartnerReferral } from '../types'

import PartnerReferralCard from '../components/PartnerReferrals/PartnerReferralCard'
import Middesk from '../components/System/Icons/Middesk'
import LandingCard from '../components/LandingCard'
import api from '../lib/api'
import { setPartnerReferralCookieCode } from '../lib/partnerCookie'

const ReferralSignupPage = (): JSX.Element => {
  const [loading, setLoading] = useState(true)
  const [
    partnerReferral,
    setPartnerReferral
  ] = useState<PartnerReferral | null>(null)

  const { referral_code } = useParams<{ referral_code: string }>()

  useEffect(() => {
    if (referral_code) {
      api
        .get(`/v1/agent/partner_referral_links/${referral_code}`)
        .then((pr: PartnerReferral) => setPartnerReferral(pr))
        /* eslint-disable-next-line no-console */
        .catch(e => console.error(e))
        .finally(() => setLoading(false))
    }
  }, [])

  useEffect(() => {
    if (partnerReferral) {
      setPartnerReferralCookieCode(partnerReferral?.referral_code)
    }
  }, [partnerReferral])

  return (
    <>
      <div style={{ position: 'absolute', top: 0, left: 0, margin: '40px' }}>
        <Middesk />
      </div>
      <LandingGlobalStyle />
      <LandingCard>
        {loading ? (
          <Loader style={{ margin: 'auto' }} />
        ) : partnerReferral ? (
          <PartnerReferralCard partnerReferral={partnerReferral} />
        ) : (
          <div style={{ margin: 'auto' }}>
            <h2>Invalid referral code</h2>
          </div>
        )}
      </LandingCard>
    </>
  )
}

export default ReferralSignupPage
