import React from 'react'
import styled, { css } from 'styled-components'
import SPACING from '../../../System/Spacing'
import { Body } from '../../../System/Typography'
import Link from '../../../System/Link'
import { DepartmentElement } from '../AccountInformation'

const Styles = css`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.small};
`

const UnemploymentInsurance = styled(({ className }) => {
  const formLink =
    'https://www.tn.gov/workforce/employers/tax-and-insurance-redirect/unemployment-insurance-tax/updating-employer-account/employers-report-of-change.html'

  return (
    <div {...{ className }}>
      <Body>
        In order to facilitate your Unemployment account transfer with the
        Tennessee Department of Labor, we need you to complete the enclosed
        online form. The Tennessee Department of Labor requires that this form
        be done online and signed by an Officer/Owner through their portal. The
        new address for the mailing address portion should be{' '}
        <b>9450 SW Gemini Dr, PMB 73938, Beaverton, OR 97008</b> and the
        requestor&apos;s email portion should be{' '}
        <em>(legal name of your business)@agent.middesk.com</em>.
      </Body>
      <Body>
        <b>Instructions for completing the form:</b>
      </Body>
      <Body>
        1. Navigate to{' '}
        <Link href={formLink} target='_blank'>
          {formLink}
        </Link>
      </Body>
      <Body>
        2. Fill out <b>Requestor&apos;s Information</b> section
      </Body>
      <Body>
        3. In the <b>Company Information</b> section under{' '}
        <b>What would you like to change?</b> select <em>Address Change</em>
      </Body>
      <Body>
        4. Under <b>What address are you wanting to change?</b> select{' '}
        <em>New Mailing Address</em>
      </Body>
      <Body>
        5. Enter <b>9450 SW Gemini Dr PMB 73938, Beaverton, OR, 97008</b> as the
        New Mailing Address
      </Body>
      <Body>6. Enter e-signature and complete verification questions</Body>
      <Body>
        7. Click <em>Submit Changes</em>
      </Body>
      <br />
    </div>
  )
})`
  ${Styles}
`

const Tennessee = (): Array<DepartmentElement> => [
  {
    name: '',
    children: [],
    renderMailingAddressCallout: true
  },
  {
    link: 'https://tnpaws.tn.gov/Forms/Login.aspx',
    name: 'Department of Labor & Workforce Development',
    children: <UnemploymentInsurance />,
    renderCompleteLater: true
  }
]

export default Tennessee
