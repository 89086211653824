import { useFormikContext } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { CheckboxField, DropzoneFile, Link } from '@middesk/components'
import { BASE_64_IDENTIFIER } from '../../lib/helpers'
import { ButtonContainer, InfoRequestValues } from '../../pages/InfoRequestForm'
import { InfoRequestItem } from '../../types'
import { encode } from 'base64-arraybuffer'
import { Body as TypographyBody } from '../System/Typography'
import SignatureUploader from '../SignatureUploader'
import SignaturePad from '../SignaturePad'
import ActionFooter from '../ActionFooter'
import LoaderButton from '../LoaderButton'
import styled from 'styled-components'
import { isString } from 'lodash'

const SIGN_MODE = 'sign'
const UPLOAD_SIGN_MODE = 'upload_sign'

const LinkWrapper = styled.div`
  text-align: end;
`

export const SignatureInfoRequest = ({
  signatureExceptionItem,
  submitting,
  name
}: {
  signatureExceptionItem: InfoRequestItem
  submitting: boolean
  name: string | undefined
}): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<InfoRequestValues>()
  const [signatureFile, setSignatureFile] = useState<DropzoneFile[]>([])
  const [signatureMode, setSignatureMode] = useState<string>(SIGN_MODE)
  const [signature, setSignature] = useState<string>(
    values.signatory.signature || ''
  )
  const signatureModeDidMountRef = useRef(false)
  const signatureFileDidMountRef = useRef(false)

  useEffect(() => {
    if (signatureModeDidMountRef.current) {
      setSignature('')
      setSignatureFile([])
    } else {
      signatureModeDidMountRef.current = true
    }
  }, [signatureMode])

  useEffect(() => {
    setFieldValue('signatory.signature', signature)
  }, [signature])

  useEffect(() => {
    if (
      signatureFileDidMountRef.current &&
      signatureFile.length === 0 &&
      signature
    ) {
      setSignature('')
    } else {
      signatureFileDidMountRef.current = true
    }
  }, [signatureFile])

  const handleDrop = (data: DropzoneFile[]) => {
    setSignatureFile([data[0]])
    isString(data[0].data)
      ? setSignature(`${BASE_64_IDENTIFIER}${data[0].data}`)
      : setSignature(`${BASE_64_IDENTIFIER}${encode(data[0].data)}`)
  }

  const handleSetFiles = (files: DropzoneFile[]) => {
    setSignatureFile(files.length > 0 ? [files[0]] : [])
  }

  const handleClear = () => {
    setSignature('')
    setFieldValue('signatory.authorized', false)
  }

  return (
    <>
      <TypographyBody>
        {name ? (
          <>
            We need <b>{name}&apos;s</b> signature in order to submit your state
            filing.
          </>
        ) : (
          <>{signatureExceptionItem.description}</>
        )}
      </TypographyBody>
      <br />
      {signatureMode === UPLOAD_SIGN_MODE ? (
        <>
          <SignatureUploader
            onDrop={handleDrop}
            onSetFiles={handleSetFiles}
            files={signatureFile}
          />
          {signatureFile.length === 0 ? (
            <ActionFooter separated={false}>
              <Link onClick={() => setSignatureMode(SIGN_MODE)}>
                Or sign here
              </Link>
            </ActionFooter>
          ) : (
            ''
          )}
        </>
      ) : (
        <SignaturePad
          {...{
            label: 'Sign here',
            onFinish: e => setSignature(e || ''),
            onClear: () => handleClear(),
            signature
          }}
        >
          <LinkWrapper>
            <Link onClick={() => setSignatureMode(UPLOAD_SIGN_MODE)}>
              Or upload a signature
            </Link>
          </LinkWrapper>
        </SignaturePad>
      )}
      <br />
      <CheckboxField
        label='I authorize Middesk to use the provided signature for filings related to the Secretary of State and Payroll Tax.'
        name='signatory.authorized'
      />
      <ButtonContainer>
        <LoaderButton
          {...{
            isDisabled:
              !values.signatory.authorized || !values.signatory.signature,
            submitting: submitting,
            submitText: 'Submit'
          }}
        />
      </ButtonContainer>
    </>
  )
}
